import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  updateAdManagerAccount,
  addOrUpdateDomainInProject,
  getDomainInProject,
} from "../../utils/api";

const AddToAdManager = ({ projectId }) => {
  const [gamId, setGamId] = useState("");
  const [domain, setDomain] = useState("");
  const [isDomainSet, setIsDomainSet] = useState(false);

  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
  const gamIdRegex = /^\d+$/;

  useState(() => {
    // Verifica se o domínio já está configurado
    const checkDomain = async () => {
      try {
        const existingDomain = await getDomainInProject(projectId);
        if (existingDomain) {
          setDomain(existingDomain);
          setIsDomainSet(true);
        }
      } catch (error) {
        toast.error("Erro ao verificar o domínio: " + error.message);
      }
    };

    checkDomain();
  }, [projectId]);

  const handleSave = async () => {
    if (!gamIdRegex.test(gamId)) {
      toast.error("O ID do Google Ad Manager deve conter apenas números.");
      return;
    }

    if (!domainRegex.test(domain)) {
      toast.error("Por favor, insira um domínio válido. Ex: seudominio.com");
      return;
    }

    try {
      // Salva o ID do Google Ad Manager no projeto
      const response = await updateAdManagerAccount(projectId, gamId);
      if (response.status === 200) {
        // Após a integração bem-sucedida com o GAM, salva o domínio
        await addOrUpdateDomainInProject(projectId, domain);
        toast.success("Google Ad Manager e domínio vinculados com sucesso!");
        setTimeout(() => {
          window.location.reload(); // Recarrega a página após 1 segundo
        }, 1000);
      } else {
        toast.error("Erro ao vincular o Google Ad Manager.");
      }
    } catch (error) {
      toast.error("Erro ao vincular o Google Ad Manager: " + error.message);
    }
  };

  return (
    <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
      <Toaster position="top-right" reverseOrder={false} />
      <h2 className="text-xl font-bold mb-4 text-gray-700">
        Adicione nosso serviço ao seu Google Ad Manager
      </h2>
      <div className="mb-4">
        <label className="text-gray-600 mb-1 block">Conta de Serviço:</label>
        <input
          type="text"
          readOnly
          value="adpilott@adpilot-423403.iam.gserviceaccount.com"
          className="border border-gray-300 p-2 rounded w-full bg-gray-100 cursor-not-allowed"
        />
      </div>
      <div className="mb-4">
        <label className="text-gray-600 mb-1 block">
          Insira o ID do Google Ad Manager:
        </label>
        <input
          type="text"
          value={gamId}
          onChange={(e) => setGamId(e.target.value)}
          placeholder="Ex: 123456789"
          className="border border-gray-300 p-2 rounded w-full focus:ring focus:ring-blue-200"
        />
      </div>
      {!isDomainSet && (
        <div className="mb-4">
          <label className="text-gray-600 mb-1 block">
            Insira o Domínio para Integrar:
          </label>
          <input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Ex: seudominio.com"
            className="border border-gray-300 p-2 rounded w-full focus:ring focus:ring-blue-200"
          />
        </div>
      )}
      <button
        onClick={handleSave}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200 w-full"
      >
        Salvar
      </button>
    </div>
  );
};

export default AddToAdManager;
