import React, { useState, useEffect } from "react";
import {
  FaClipboard,
  FaUser,
  FaEnvelope,
  FaCoins,
  FaCalendarAlt,
  FaClock,
  FaCheckCircle,
  FaExclamationCircle,
  FaTimes,
} from "react-icons/fa";
import {
  getUsers,
  getUserById,
  addUserScope,
  removeUserScope,
  deleteUser,
  banUser,
  addCredits,
  decrementCredits,
} from "../utils/api";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [scopes, setScopes] = useState("");
  const [credits, setCredits] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      if (!payload.scopes || !payload.scopes.includes("admin")) {
        navigate("/access-denied");
        return;
      }
    }

    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        if (response.success) {
          setUsers(response.data);
        }
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("pt-BR", options).format(
      new Date(dateString)
    );
  };

  const handleDeleteUser = (id) => {
    confirmAlert({
      title: "Confirmar exclusão",
      message: "Tem certeza que deseja deletar este usuário?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await deleteUser(id);
              setUsers(users.filter((user) => user.id !== id));
              toast.success("Usuário deletado com sucesso!");
            } catch (err) {
              toast.error("Erro ao deletar usuário.");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleViewUser = async (id) => {
    try {
      const response = await getUserById(id);
      setSelectedUser(response.data);
      setModalVisible(true);
    } catch (err) {
      toast.error("Erro ao obter detalhes do usuário.");
    }
  };

  const handleAddScope = async (id) => {
    try {
      const response = await addUserScope(id, [scopes]);
      setSelectedUser(response.data);
      setScopes("");
      toast.success("Escopo adicionado com sucesso!");
    } catch (err) {
      toast.error("Erro ao adicionar escopo ao usuário.");
    }
  };

  const handleRemoveScope = async (id) => {
    try {
      const response = await removeUserScope(id, [scopes]);
      setSelectedUser(response.data);
      setScopes("");
      toast.success("Escopo removido com sucesso!");
    } catch (err) {
      toast.error("Erro ao remover escopo do usuário.");
    }
  };

  const handleBanUser = async (id) => {
    confirmAlert({
      title: "Confirmar banimento",
      message: "Tem certeza que deseja banir este usuário?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await banUser(id);
              setSelectedUser(null);
              setModalVisible(false);
              toast.success("Usuário banido com sucesso!");
            } catch (err) {
              toast.error("Erro ao banir usuário.");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAddCredits = async (id, amount) => {
    try {
      const response = await addCredits(id, amount);
      setSelectedUser(response.data);
      toast.success("Créditos adicionados com sucesso!");
    } catch (err) {
      toast.error("Erro ao adicionar créditos ao usuário.");
    }
  };

  const handleDecrementCredits = async (id, amount) => {
    try {
      const response = await decrementCredits(id, amount);
      setSelectedUser(response.data);
      toast.success("Créditos decrementados com sucesso!");
    } catch (err) {
      toast.error("Erro ao decrementar créditos do usuário.");
    }
  };

  const isUserBanned = (user) => {
    return !user.scopes.includes("login");
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div
          className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-blue-500"
          role="status"
        >
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4">
      <Toaster position="top-right" />
      <h2 className="text-2xl font-semibold text-center my-4 text-gray-800">
        Gerenciamento de Usuários
      </h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Pesquisar usuários..."
          className="px-4 py-2 border rounded w-full text-sm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="min-w-full bg-white border border-gray-300 text-sm rounded-lg shadow-sm">
        <thead>
          <tr className="bg-gray-200 text-gray-700">
            <th className="p-3 text-left">
              <FaClipboard size={14} className="inline-block mr-1" /> ID
            </th>
            <th className="p-3 text-left">
              <FaUser size={14} className="inline-block mr-1" /> Nome
            </th>
            <th className="p-3 text-left">
              <FaEnvelope size={14} className="inline-block mr-1" /> Email
            </th>
            <th className="p-3 text-left">
              <FaUser size={14} className="inline-block mr-1" /> Username
            </th>
            <th className="p-3 text-center">
              <FaCoins size={14} className="inline-block mr-1" /> Créditos
            </th>
            <th className="p-3 text-center">
              <FaCalendarAlt size={14} className="inline-block mr-1" /> Inscrito
              em
            </th>
            <th className="p-3 text-center">
              <FaClock size={14} className="inline-block mr-1" /> Último Uso
            </th>
            <th className="p-3 text-center">
              <FaCheckCircle size={14} className="inline-block mr-1" /> Status
            </th>
            <th className="p-3 text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id} className="hover:bg-gray-50 text-gray-700">
              <td className="p-3 text-left">{user.id}</td>
              <td className="p-3 truncate max-w-xs">{user.name}</td>
              <td className="p-3 truncate max-w-xs">{user.email}</td>
              <td className="p-3 text-left">{user.username}</td>
              <td className="p-3 text-center">{user.credits}</td>
              <td className="p-3 text-center">{formatDate(user.createdAt)}</td>
              <td className="p-3 text-center">{formatDate(user.updatedAt)}</td>
              <td className="p-3 text-center">
                {!isUserBanned(user) ? (
                  <FaCheckCircle className="text-green-400 inline-block" />
                ) : (
                  <FaExclamationCircle className="text-red-400 inline-block" />
                )}
                <span className="ml-1">
                  {!isUserBanned(user) ? "Ativo" : "Banido"}
                </span>
              </td>
              <td className="p-3 flex space-x-2 justify-center">
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded text-sm"
                  onClick={() => handleViewUser(user.id)}
                >
                  Visualizar
                </button>
                <button
                  className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded text-sm"
                  onClick={() => handleDeleteUser(user.id)}
                >
                  Deletar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalVisible && selectedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 overflow-y-auto">
          <div className="bg-gray-100 rounded-lg p-8 max-w-lg w-full relative">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={() => setModalVisible(false)}
            >
              <FaTimes size={20} />
            </button>
            <h2 className="text-xl font-bold mb-4 text-gray-800">
              Detalhes do Usuário
            </h2>
            <div className="mb-4 text-gray-800">
              <p>
                <strong>ID:</strong> {selectedUser.id}
              </p>
              <p>
                <strong>Nome:</strong> {selectedUser.name}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser.email}
              </p>
              <p>
                <strong>Username:</strong> {selectedUser.username}
              </p>
              <p>
                <strong>Créditos:</strong> {selectedUser.credits}
              </p>
              <p>
                <strong>Escopos:</strong> {selectedUser.scopes.join(", ")}
              </p>
              <p>
                <strong>Inscrito em:</strong>{" "}
                {formatDate(selectedUser.createdAt)}
              </p>
              <p>
                <strong>Último Uso:</strong>{" "}
                {formatDate(selectedUser.updatedAt)}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                {!isUserBanned(selectedUser) ? "Ativo" : "Banido"}
              </p>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-gray-600 mb-1">
                  Adicionar ou Remover Escopo
                </label>
                <input
                  type="text"
                  className="bg-white text-gray-800 rounded py-2 px-4 w-full mb-2 border border-gray-300"
                  placeholder="Adicionar ou remover escopo"
                  value={scopes}
                  onChange={(e) => setScopes(e.target.value)}
                />
                <div className="flex space-x-2">
                  <button
                    className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded w-full"
                    onClick={() => handleAddScope(selectedUser.id)}
                  >
                    Adicionar Escopo
                  </button>
                  <button
                    className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded w-full"
                    onClick={() => handleRemoveScope(selectedUser.id)}
                  >
                    Remover Escopo
                  </button>
                </div>
              </div>

              <div>
                <label className="block text-gray-600 mb-1">
                  Gerenciar Créditos
                </label>
                <input
                  type="number"
                  className="bg-white text-gray-800 rounded py-2 px-4 w-full mb-2 border border-gray-300"
                  placeholder="Adicionar Créditos"
                  value={credits}
                  onChange={(e) => setCredits(e.target.value)}
                />
                <div className="flex space-x-2">
                  <button
                    className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded w-full"
                    onClick={() => handleAddCredits(selectedUser.id, credits)}
                  >
                    Adicionar Créditos
                  </button>
                  <button
                    className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded w-full"
                    onClick={() =>
                      handleDecrementCredits(selectedUser.id, credits)
                    }
                  >
                    Decrementar Créditos
                  </button>
                </div>
              </div>

              <button
                className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded w-full"
                onClick={() => handleBanUser(selectedUser.id)}
              >
                Banir Usuário
              </button>

              <button
                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded w-full mt-4"
                onClick={() => setModalVisible(false)}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
