import React from "react";

const AlertMessage = ({ errorMessage, successMessage, copyMessage }) => (
  <div className="mb-4">
    {errorMessage && (
      <div className="text-red-600 text-sm p-2 rounded bg-red-100 border border-red-400">
        {errorMessage}
      </div>
    )}
    {successMessage && (
      <div className="text-green-600 text-sm p-2 rounded bg-green-100 border border-green-400">
        {successMessage}
      </div>
    )}
    {copyMessage && (
      <div className="text-blue-600 text-sm p-2 rounded bg-blue-100 border border-blue-400">
        {copyMessage}
      </div>
    )}
  </div>
);

export default AlertMessage;
