import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import { getCampaignReportByDate } from "../utils/api";

const CampaignChart = ({ projectId, campaignId, adGroupId, initialDate }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const timezone = "America/Sao_Paulo";
  const [selectedDate, setSelectedDate] = useState(
    initialDate || dayjs().format("YYYY-MM-DD")
  );

  const fetchReportData = async (date) => {
    if (projectId && campaignId && adGroupId) {
      setIsLoading(true);
      const response = await getCampaignReportByDate(
        projectId,
        campaignId,
        adGroupId,
        date
      );

      if (response) {
        const reports = response || [];
        setError("");

        if (reports.length === 0) {
          setChartOptions(null);
          setError("No data available for this campaign.");
          setIsLoading(false);
          return;
        }

        const validData = reports.filter(
          (item) => item.createdAt && !isNaN(new Date(item.createdAt))
        );

        validData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        const timeLabels = validData.map((item) =>
          dayjs(item.createdAt).tz(timezone).format("HH:mm")
        );

        const uniqueTimes = [...new Set(timeLabels)];

        const filledData = {
          times: [],
          cpcData: [],
          googleAdsCostData: [],
          roiData: [],
        };

        uniqueTimes.forEach((time) => {
          const dataIndex = timeLabels.indexOf(time);
          filledData.times.push(time);

          const currentBid = validData[dataIndex]?.currentBid || 0;

          filledData.cpcData.push(
            currentBid > 0 ? Number(currentBid.toFixed(2)) : null
          );

          filledData.googleAdsCostData.push(
            validData[dataIndex]?.googleAdsCost
              ? Number(validData[dataIndex].googleAdsCost.toFixed(2))
              : null
          );

          filledData.roiData.push(
            validData[dataIndex]?.roiPercentage
              ? Number(validData[dataIndex].roiPercentage.toFixed(2))
              : null
          );
        });

        // Opções do gráfico com largura 100% e altura compacta
        setChartOptions({
          chart: {
            type: "line",
            backgroundColor: "transparent",
            style: {
              fontFamily: "'Inter', sans-serif",
            },
            height: 325, // Altura fixa do gráfico
            marginBottom: 60, // Ajusta o espaço em branco abaixo do gráfico
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: filledData.times,
            lineColor: "#ddd",
            tickColor: "#ddd",
            labels: {
              style: {
                color: "#888",
              },
              rotation: -45,
              align: "right",
            },
          },
          yAxis: [
            {
              title: {
                text: "",
              },
              gridLineColor: "#f0f0f0",
              labels: {
                style: {
                  color: "#888",
                },
                format: "R$ {value:.2f}",
              },
            },
            {
              title: {
                text: "",
              },
              gridLineColor: "#f0f0f0",
              labels: {
                style: {
                  color: "#888",
                },
                format: "{value}%",
              },
              opposite: true,
            },
          ],
          series: [
            {
              name: "CPC (BRL)",
              data: filledData.cpcData,
              color: "#1c64f2",
              marker: {
                enabled: true, // Habilita os bullets (pontos)
                radius: 3, // Tamanho dos bullets
                symbol: "circle",
              },
            },
            {
              name: "Google Ads Cost (BRL)",
              data: filledData.googleAdsCostData,
              color: "#10b981",
              marker: {
                enabled: true, // Habilita os bullets (pontos)
                radius: 3, // Tamanho dos bullets
                symbol: "circle",
              },
            },
            {
              name: "ROI (%)",
              data: filledData.roiData,
              color: "#f59e0b",
              marker: {
                enabled: true, // Habilita os bullets (pontos)
                radius: 3, // Tamanho dos bullets
                symbol: "circle",
              },
              yAxis: 1,
            },
          ],
          legend: {
            enabled: false,
          },
          tooltip: {
            shared: true,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderColor: "#ddd",
            shadow: false,
            style: {
              color: "#333",
            },
            valueDecimals: 2,
          },
          credits: {
            enabled: false,
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  chart: {
                    height: 250, // Ajusta a altura em telas menores
                  },
                  xAxis: {
                    labels: {
                      rotation: -30, // Menos rotação para labels em telas menores
                    },
                  },
                },
              },
            ],
          },
        });
      } else {
        setChartOptions(null);
        setError(response.error?.message || "Erro ao carregar o relatório.");
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData(selectedDate);
  }, [projectId, campaignId, selectedDate]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
  };

  return (
    <div className="bg-gray-100 py-4">
      <div className="container mx-auto">
        <div className="mb-4">
          <label
            htmlFor="date"
            className="block text-gray-700 font-semibold mb-2"
          >
            Selecionar Data:
          </label>
          <input
            type="date"
            id="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="border border-gray-300 p-2 rounded"
          />
        </div>

        {isLoading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p className="text-red-500">
            {typeof error === "string"
              ? error
              : error.message || "Ocorreu um erro."}
          </p>
        ) : (
          chartOptions && (
            <div className="bg-white p-4 rounded shadow mb-8">
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CampaignChart;
