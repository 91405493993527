import React from "react";

const LabelField = ({ campaignData, handleInputChange }) => (
  <div id="labelField" className="mb-4">
    <label
      className="block text-blue-600 text-sm font-bold mb-2"
      htmlFor="label"
    >
      Rótulo
    </label>
    <input
      id="label"
      type="text"
      value={campaignData.label}
      onChange={handleInputChange}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
    <p className="text-yellow-600 text-sm">
      Certifique-se de que este rótulo exista na sua conta do Google Ads.
    </p>
  </div>
);

export default LabelField;
