import React from "react";
import PropTypes from "prop-types";

// Componente que recebe o eventName e a lista de eventos e retorna a contagem do evento específico
const DisplayEventCount = ({ eventName, eventList }) => {
  // Função para buscar o evento específico na lista
  const getEventCount = (eventName, eventList) => {
    if (!Array.isArray(eventList)) {
      console.warn("Invalid eventList format, expected an array.");
      return "0";
    }

    const event = eventList.find(
      (item) => item && item.eventName === eventName
    );

    if (event && typeof event.count === "string" && !isNaN(event.count)) {
      return event.count;
    } else if (event && typeof event.count === "number") {
      return event.count.toString();
    } else {
      return "0"; // Retorna "0" se o evento não for encontrado ou se o formato for inválido
    }
  };

  // Retorna o valor da contagem em plain text
  return <span>{getEventCount(eventName, eventList)}</span>;
};

// Adiciona validação de tipos para as props
DisplayEventCount.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      eventName: PropTypes.string,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

// Define um valor padrão para eventList caso ele não seja passado
DisplayEventCount.defaultProps = {
  eventList: [],
};

export default DisplayEventCount;
