import React from "react";

const LoadingButton = ({ isLoading }) => (
  <button
    id="saveButton"
    className={`bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
      isLoading ? "opacity-50 cursor-not-allowed" : ""
    }`}
    type="submit"
    disabled={isLoading}
  >
    {isLoading ? (
      <div className="flex items-center justify-center">
        <svg
          className="animate-spin h-5 w-5 mr-3 text-white"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8z"
          ></path>
        </svg>
        Processando...
      </div>
    ) : (
      "Criar Campanha"
    )}
  </button>
);

export default LoadingButton;
