import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { saveGoogleAdsTokens } from "../utils/api";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const CompleteIntegration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    // Recupera os dados do localStorage
    const integrationData = JSON.parse(
      localStorage.getItem("googleAdsIntegration")
    );

    if (code && integrationData) {
      handleSaveTokens(code, integrationData.projectId);
    } else {
      toast.error("Dados de integração não encontrados.");
    }
  }, []);

  const handleSaveTokens = async (code, projectId) => {
    try {
      // Recupera o token JWT e decodifica para obter o ID da conta
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const accountId = decodedToken.id;

      // Envia o código e os dados para salvar os tokens
      const response = await saveGoogleAdsTokens(code, projectId, accountId);
      if (response.success) {
        toast.success("Conta Google Ads integrada com sucesso!");
        navigate(`/project/${projectId}`); // Redireciona para a página do projeto
      } else {
        toast.error("Falha ao integrar a conta Google Ads.");
      }
    } catch (error) {
      console.error("Erro ao salvar tokens:", error);
      toast.error("Ocorreu um erro ao tentar salvar os tokens.");
    } finally {
      removeUrlParams(); // Remove os parâmetros da URL após a verificação
      localStorage.removeItem("googleAdsIntegration"); // Limpa os dados temporários
    }
  };

  const removeUrlParams = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
      <Toaster position="top-right" reverseOrder={false} />
      <p className="text-gray-600">Processando integração...</p>
    </div>
  );
};

export default CompleteIntegration;
