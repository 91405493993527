import React from "react";

const CopyCsvButton = ({ handleCopyCsv }) => (
  <button
    id="copyCsvButton"
    type="button"
    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-28"
    onClick={handleCopyCsv}
  >
    Copiar CSV
  </button>
);

export default CopyCsvButton;
