import React, { useState, useEffect } from "react";
import {
  FaClipboard,
  FaBullhorn,
  FaUsers,
  FaCheckCircle,
  FaClock,
  FaExclamationCircle,
  FaFlag,
  FaTags,
  FaLink,
  FaDollarSign,
  FaCalendarAlt,
  FaCopy,
} from "react-icons/fa";
import { getCampaignLogs } from "../utils/api";
import toast, { Toaster } from "react-hot-toast";

const CampaignsHistory = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await getCampaignLogs();
        if (response.success) {
          const sortedCampaigns = response.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setCampaigns(sortedCampaigns);
          setFilteredCampaigns(sortedCampaigns);
        }
      } catch (error) {
        console.error("Erro ao carregar campanhas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredCampaigns(campaigns);
    } else {
      const filtered = campaigns.filter(
        (campaign) =>
          campaign.campaignName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          campaign.adGroupName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          campaign.finalUrl.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCampaigns(filtered);
    }
  }, [searchTerm, campaigns]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("pt-BR", options).format(
      new Date(dateString)
    );
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copiado para o clipboard!");
    } catch (error) {
      toast.error("Erro ao copiar o CSV.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 border-4 border-gray-400 border-t-transparent rounded-full animate-spin"></div>
          <span className="text-gray-600">Carregando...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 max-w-full lg:max-w-screen-xl">
      <Toaster position="top-right" reverseOrder={false} />
      <h2 className="text-2xl font-semibold text-center my-4 text-gray-800">
        Histórico de Campanhas
      </h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Pesquisar campanhas..."
          className="px-4 py-2 border rounded w-full text-sm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto max-w-screen-lg mx-auto">
        <table className="w-full bg-white border border-gray-300 text-sm rounded-lg shadow-sm table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="p-3 text-left w-20">
                <FaClipboard size={14} className="inline-block mr-1" /> ID
              </th>
              <th className="p-3 text-center w-32">
                <FaCheckCircle size={14} className="inline-block mr-1" /> Status
              </th>
              <th className="p-3 text-center w-32">
                <FaCopy size={14} className="inline-block mr-1" /> Copiar CSV
              </th>
              <th className="p-3 text-left w-48">
                <FaBullhorn size={14} className="inline-block mr-1" /> Nome da
                Campanha
              </th>
              <th className="p-3 text-left w-48">
                <FaUsers size={14} className="inline-block mr-1" /> Nome do
                Grupo
              </th>
              <th className="p-3 text-center w-32">
                <FaDollarSign size={14} className="inline-block mr-1" /> CPC
                Máximo
              </th>
              <th className="p-3 text-center w-32">
                <FaFlag size={14} className="inline-block mr-1" /> País
              </th>
              <th className="p-3 text-center w-32">
                <FaTags size={14} className="inline-block mr-1" /> Manual UTM
              </th>
              <th className="p-3 text-center w-32">
                <FaTags size={14} className="inline-block mr-1" /> Source
              </th>
              <th className="p-3 text-center w-32">
                <FaTags size={14} className="inline-block mr-1" /> Medium
              </th>
              <th className="p-3 text-center w-48 truncate">
                <FaLink size={14} className="inline-block mr-1" /> URL Final
              </th>
              <th className="p-3 text-center w-32">
                <FaCalendarAlt size={14} className="inline-block mr-1" /> Criado
                em
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCampaigns.map((campaign) => (
              <tr key={campaign.id} className="hover:bg-gray-50 text-gray-700">
                <td className="p-3 text-center">{campaign.id}</td>
                <td className="p-3 text-center">
                  {campaign.status?.toLowerCase() === "completed" && (
                    <FaCheckCircle className="text-green-400 inline-block" />
                  )}
                  {campaign.status?.toLowerCase() === "pending" && (
                    <FaClock className="text-yellow-400 inline-block" />
                  )}
                  {campaign.status?.toLowerCase() === "failed" && (
                    <FaExclamationCircle className="text-red-400 inline-block" />
                  )}
                  <span className="ml-1">
                    {campaign.status?.toLowerCase() === "completed"
                      ? "Concluído"
                      : campaign.status?.toLowerCase() === "pending"
                      ? "Pendente"
                      : "Erro"}
                  </span>
                </td>
                <td className="p-3 text-center">
                  {campaign.status === "completed" && campaign.finalCsv && (
                    <button
                      onClick={() => handleCopy(campaign.finalCsv)}
                      className="text-blue-500 hover:text-blue-700 transition-colors duration-200"
                    >
                      <FaCopy size={16} />
                    </button>
                  )}
                </td>
                <td className="p-3 truncate">{campaign.campaignName}</td>
                <td className="p-3 truncate">{campaign.adGroupName}</td>
                <td className="p-3 text-center">{campaign.cpcMax}</td>
                <td className="p-3 text-center">{campaign.country}</td>
                <td className="p-3 text-center">
                  {campaign.manualUTM ? campaign.manualUTM : "N/A"}
                </td>
                <td className="p-3 text-center">
                  {campaign.manualSource ? campaign.manualSource : "N/A"}
                </td>
                <td className="p-3 text-center">
                  {campaign.manualMedium ? campaign.manualMedium : "N/A"}
                </td>
                <td className="p-3 text-center truncate">
                  <a
                    href={campaign.finalUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {new URL(campaign.finalUrl).hostname}
                  </a>
                </td>
                <td className="p-3 text-center">
                  {formatDate(campaign.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CampaignsHistory;
