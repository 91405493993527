import React from "react";

const ManualUtmFields = ({ campaignData, handleInputChange }) => (
  <div id="manualUtmFields">
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="manualUTM"
      >
        UTM Campaign
      </label>
      <input
        id="manualUTM"
        type="text"
        value={campaignData.manualUTM}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="manualMedium"
      >
        UTM Medium
      </label>
      <input
        id="manualMedium"
        type="text"
        value={campaignData.manualMedium}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="manualSource"
      >
        UTM Source
      </label>
      <input
        id="manualSource"
        type="text"
        value={campaignData.manualSource}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
  </div>
);

export default ManualUtmFields;
