import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  FaGoogle,
  FaGlobe,
  FaUserTie,
  FaCog,
  FaChartLine,
  FaTrash,
  FaCheckCircle,
  FaInfoCircle,
} from "react-icons/fa";
import {
  getProjectById,
  addOrUpdateProjectConfig,
  updateGoogleAdsAccount,
  getDomainInProject,
  addOrUpdateDomainInProject,
  updateAdManagerAccount,
  addOrUpdateManagerInProject,
  getManagerIdInProject,
  removeManagerFromProject,
} from "../utils/api";
import IntegrateAccount from "../components/googleads/IntegrateAccount";
import AddToAdManager from "../components/admanager/AddToAdManager";
import { jwtDecode } from "jwt-decode";

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [domain, setDomain] = useState("");
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [managers, setManagers] = useState([]);
  const [newManager, setNewManager] = useState("");
  const [configurations, setConfigurations] = useState({
    RevShare: "",
    Tax: "",
    DollarDepreciation: "",
  });
  const [roiTargets, setRoiTargets] = useState({
    roiGoal: "",
    roiAbove: "",
    roiBelow: "",
  });
  const [roiAboveThreshold, setRoiAboveThreshold] = useState("");
  const [roiBelowThreshold, setRoiBelowThreshold] = useState("");
  const [accountId, setAccountId] = useState("");
  const [newDomain, setNewDomain] = useState("");
  const [isConfigVisible, setIsConfigVisible] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchProject = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error(
            "Token não encontrado. Por favor, faça login novamente."
          );
        }

        const decodedToken = jwtDecode(token);
        const accountId = decodedToken.id;
        setAccountId(accountId);
        if (!accountId) {
          throw new Error("ID da conta não encontrado no token.");
        }

        const projectData = await getProjectById(id);
        if (!projectData) {
          throw new Error("Projeto não encontrado.");
        }

        setProject(projectData);

        const { config } = projectData;
        if (config) {
          setConfigurations({
            RevShare: config.RevShare || "",
            Tax: config.Tax || "",
            DollarDepreciation: config.DollarDepreciation || "",
          });
          setRoiTargets({
            roiGoal: config.roiGoal || "",
            roiAbove: config.roiAbove || "",
            roiBelow: config.roiBelow || "",
          });
        }

        if (projectData.googleAdsId) {
          setGoogleAdsAccounts(projectData.googleAdsId);
          if (projectData.googleAdsId.length === 1) {
            setSelectedAccount(projectData.googleAdsId[0]);
          }
        }

        const domainData = await getDomainInProject(id, accountId);
        setDomain(domainData || "");

        const managerData = await getManagerIdInProject(id);
        if (managerData && managerData.managers) {
          setManagers(managerData.managers);
        }
      } catch (error) {
        const errorMessage =
          typeof error === "string"
            ? error
            : error.message || "Erro desconhecido";
        setError(errorMessage);
        toast.error(errorMessage);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProject();
  }, [id, navigate]);

  const handleRemoveManager = async (managerId) => {
    toast.loading("Removendo gestor de tráfego...");
    try {
      await removeManagerFromProject(id, managerId);
      setManagers((prev) => prev.filter((manager) => manager.id !== managerId));
      toast.dismiss();
      toast.success("Gestor de tráfego removido com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao remover o gestor de tráfego: " + error.message);
    }
  };

  const handleAddManager = async () => {
    if (!newManager.trim()) {
      toast.error("Por favor, preencha o ID ou Username do Gestor de Tráfego.");
      return;
    }

    toast.loading("Adicionando gestor de tráfego...");
    try {
      const result = await addOrUpdateManagerInProject(id, newManager);
      if (result && result.error) {
        throw new Error(result.error.error);
      }
      setManagers((prev) => [
        ...prev,
        { id: result.id, name: result.name, username: result.username },
      ]);
      setNewManager("");
      toast.dismiss();
      toast.success("Gestor de tráfego adicionado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const handleSaveDomain = async () => {
    if (!newDomain.trim()) {
      toast.error("Por favor, insira um domínio válido.");
      return;
    }

    toast.loading("Salvando domínio...");
    try {
      await addOrUpdateDomainInProject(id, newDomain);
      setDomain(newDomain);
      setNewDomain("");
      toast.dismiss();
      toast.success("Domínio adicionado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao adicionar o domínio: " + error.message);
    }
  };

  const handleIntegrateGoogleAds = async () => {
    try {
      await updateGoogleAdsAccount(id, selectedAccount);
      toast.success("Conta do Google Ads integrada com sucesso!");
    } catch (error) {
      toast.error("Erro ao integrar conta do Google Ads: " + error.message);
    }
  };

  const handleIntegrateAdManager = async () => {
    try {
      await updateAdManagerAccount(id);
      toast.success("Conta do Google Ad Manager integrada com sucesso!");
    } catch (error) {
      toast.error(
        "Erro ao integrar conta do Google Ad Manager: " + error.message
      );
    }
  };

  const handleConfigBlur = async (key, value) => {
    if (!value.trim()) {
      toast.error("Por favor, preencha este campo.");
      return;
    }

    setConfigurations((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    toast.loading("Salvando configurações...");

    try {
      await addOrUpdateProjectConfig(id, key, value);
      toast.dismiss();
      toast.success(`${key} atualizado com sucesso.`);
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const handleRoiBlur = async (key, value) => {
    if (!value.trim()) {
      toast.error("Por favor, preencha este campo.");
      return;
    }

    setRoiTargets((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    toast.loading("Salvando ajustes de ROI...");

    try {
      await addOrUpdateProjectConfig(id, key, value);
      toast.dismiss();
      toast.success(`${key} atualizado com sucesso.`);
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const calculateRoiThresholds = (goal, above, below) => {
    const roiGoal = parseFloat(goal);
    const roiAbove = parseFloat(above);
    const roiBelow = parseFloat(below);

    if (!isNaN(roiGoal) && !isNaN(roiAbove)) {
      setRoiAboveThreshold((roiGoal * (1 + roiAbove / 100)).toFixed(2));
    } else {
      setRoiAboveThreshold("");
    }

    if (!isNaN(roiGoal) && !isNaN(roiBelow)) {
      setRoiBelowThreshold((roiGoal * (1 - roiBelow / 100)).toFixed(2));
    } else {
      setRoiBelowThreshold("");
    }
  };

  const toggleConfigVisibility = () => {
    setIsConfigVisible(!isConfigVisible);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-gray-600">Carregando...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
          <FaCog className="mr-2" />
          Configuração de Arbitragem: Projeto {project.projectType}
        </h1>
        <p className="text-sm text-gray-500 mb-8 flex items-center">
          <FaInfoCircle className="mr-2" />
          Configure as integrações necessárias para otimizar seu projeto de
          arbitragem. O domínio, Google Ads e Google Ad Manager são obrigatórios
          para a correta execução.
        </p>
        <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 flex items-center">
            <FaGlobe className="text-blue-500 mr-2" />
            <div>
              <h3 className="text-lg font-semibold mb-2">Domínio</h3>
              <p className="text-gray-700">
                {domain || (
                  <>
                    <input
                      type="text"
                      value={newDomain}
                      onChange={(e) => setNewDomain(e.target.value)}
                      placeholder="example.com"
                      className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                    />
                    <button
                      onClick={handleSaveDomain}
                      className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    >
                      Adicionar Domínio
                    </button>
                  </>
                )}
              </p>
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 flex items-center">
            <FaGoogle className="text-green-500 mr-2" />
            <div>
              <h3 className="text-lg font-semibold mb-2">
                Google Ads Integrado
              </h3>
              <p className="text-gray-700">
                {googleAdsAccounts.length
                  ? googleAdsAccounts.join(", ")
                  : "Nenhuma conta vinculada"}
              </p>
              {!googleAdsAccounts.length && (
                <IntegrateAccount projectId={project.id} />
              )}
              {googleAdsAccounts.length > 1 && (
                <div>
                  <label className="text-gray-600 mb-1">
                    Selecione a Conta Google Ads:
                  </label>
                  <select
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.target.value)}
                    onBlur={handleIntegrateGoogleAds}
                    className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                  >
                    <option value="">Selecione uma conta</option>
                    {googleAdsAccounts.map((account) => (
                      <option key={account} value={account}>
                        {account}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 flex items-center">
            <FaChartLine className="text-purple-500 mr-2" />
            <div>
              <h3 className="text-lg font-semibold mb-2">
                Google Ad Manager Integrado
              </h3>
              <p className="text-gray-700">
                {project.admanagerId || (
                  <AddToAdManager projectId={project.id} />
                )}
              </p>
            </div>
          </div>
        </div>

        {project.accountId === accountId && (
          <div className="mt-8">
            <h3 className="text-lg font-semibold mb-4">Gestores de Tráfego</h3>
            {managers.length > 0 ? (
              <div className="flex flex-col space-y-2">
                {managers.map((manager) => (
                  <div
                    key={manager.id}
                    className="flex justify-between items-center bg-gray-100 p-2 rounded shadow-sm"
                  >
                    <div className="text-sm">
                      <p className="font-medium">{manager.name}</p>
                      <p className="text-gray-500">{manager.username}</p>
                    </div>
                    <button
                      onClick={() => handleRemoveManager(manager.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600">
                Nenhum gestor de tráfego adicionado.
              </p>
            )}

            <div className="mt-4 flex items-center">
              <input
                type="text"
                value={newManager}
                onChange={(e) => setNewManager(e.target.value)}
                placeholder="ID ou Username do Gestor de Tráfego"
                className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
              />
              <button
                onClick={handleAddManager}
                className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Adicionar
              </button>
            </div>
          </div>
        )}

        {/* Configurações */}
        {project.accountId === accountId && (
          <>
            <button
              onClick={toggleConfigVisibility}
              className="mt-8 text-blue-500 hover:underline text-sm flex items-center"
            >
              <FaCog className="mr-1" />
              {isConfigVisible
                ? "Ocultar Configurações"
                : "Mostrar Configurações"}
            </button>
            {isConfigVisible && (
              <div className="mt-4 grid grid-cols-1 gap-6">
                <div className="flex flex-col">
                  <label className="text-gray-600 mb-1 text-sm">
                    RevShare (Porcentagem de Receita Compartilhada):
                  </label>
                  <input
                    type="text"
                    value={configurations.RevShare}
                    onChange={(e) =>
                      setConfigurations({
                        ...configurations,
                        RevShare: e.target.value,
                      })
                    }
                    onBlur={(e) => handleConfigBlur("RevShare", e.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-600 mb-1 text-sm">
                    Tax (Taxa em Porcentagem):
                  </label>
                  <input
                    type="text"
                    value={configurations.Tax}
                    onChange={(e) =>
                      setConfigurations({
                        ...configurations,
                        Tax: e.target.value,
                      })
                    }
                    onBlur={(e) => handleConfigBlur("Tax", e.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-600 mb-1 text-sm">
                    Dollar Depreciation (Depreciação do Dólar em Centavos):
                  </label>
                  <input
                    type="text"
                    value={configurations.DollarDepreciation}
                    onChange={(e) =>
                      setConfigurations({
                        ...configurations,
                        DollarDepreciation: e.target.value,
                      })
                    }
                    onBlur={(e) =>
                      handleConfigBlur("DollarDepreciation", e.target.value)
                    }
                    className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                  />
                </div>
              </div>
            )}

            {/* ROI Configuration */}
            {isConfigVisible && (
              <div className="mt-8">
                <h2 className="text-lg font-semibold mb-4">
                  <FaCheckCircle className="mr-1" />
                  Meta de ROI (Retorno sobre Investimento)
                </h2>
                <div className="grid grid-cols-1 gap-6">
                  <div className="flex flex-col">
                    <label className="text-gray-600 mb-1 text-sm">
                      Meta de ROI (%):
                    </label>
                    <input
                      type="text"
                      value={roiTargets.roiGoal}
                      onChange={(e) =>
                        setRoiTargets({
                          ...roiTargets,
                          roiGoal: e.target.value,
                        })
                      }
                      onBlur={(e) => handleRoiBlur("roiGoal", e.target.value)}
                      className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="text-gray-600 mb-1 text-sm">
                      Quanto (%) acima da meta consideraremos ROI:
                    </label>
                    <input
                      type="text"
                      value={roiTargets.roiAbove}
                      onChange={(e) =>
                        setRoiTargets({
                          ...roiTargets,
                          roiAbove: e.target.value,
                        })
                      }
                      onBlur={(e) => handleRoiBlur("roiAbove", e.target.value)}
                      className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                    />
                    {roiAboveThreshold && (
                      <p className="text-gray-500 text-sm mt-1">
                        ROI acima da meta: {roiAboveThreshold}%
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <label className="text-gray-600 mb-1 text-sm">
                      Quanto (%) abaixo da meta consideraremos ROI:
                    </label>
                    <input
                      type="text"
                      value={roiTargets.roiBelow}
                      onChange={(e) =>
                        setRoiTargets({
                          ...roiTargets,
                          roiBelow: e.target.value,
                        })
                      }
                      onBlur={(e) => handleRoiBlur("roiBelow", e.target.value)}
                      className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                    />
                    {roiBelowThreshold && (
                      <p className="text-gray-500 text-sm mt-1">
                        ROI abaixo da meta: {roiBelowThreshold}%
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className="mt-8">
          <Link
            to={`/project/${id}/performance`}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <FaChartLine className="mr-2" />
            Visualizar performance do projeto
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
