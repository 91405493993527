import React from "react";
import CampaignForm from "../components/CampaignForm.jsx";

const CampaignCreation = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold text-blue-600 mb-6 text-center">
      Configuração da Criação da Campanha
    </h1>
    <CampaignForm />
  </div>
);

export default CampaignCreation;
