import React from "react";
import { CreditsProvider } from "./CreditsContext";
// Importar outros contextos conforme necessário

const AppProviders = ({ children }) => {
  return (
    <CreditsProvider>
      {/* Adicione outros providers aqui */}
      {children}
    </CreditsProvider>
  );
};

export default AppProviders;
