import React from "react";
import { Link } from "react-router-dom";

const AccessDeniedPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-900 text-white">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Ops! Acesso Negado</h1>
        <p className="text-xl mb-6">
          Parece que você não tem permissão para acessar esta página.
        </p>
        <p className="text-lg mb-4">
          Mas não se preocupe, você pode voltar para a{" "}
          <Link to="/dashboard" className="text-blue-400 underline">
            página inicial
          </Link>{" "}
          ou explorar outras funcionalidades.
        </p>
        <p className="text-sm text-gray-400">
          Se você acha que isso é um erro, entre em contato com o suporte.
        </p>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
