import React, { useState } from "react";
import ManualUtmFields from "../components/ManualUtmFields";
import LabelField from "../components/LabelField";

const AdvancedSettingsSection = ({
  campaignData,
  handleInputChange,
  isManualUtm,
  setIsManualUtm,
  isLabel,
  setIsLabel,
}) => {
  const [isDynamicSitelink, setIsDynamicSitelink] = useState(false);

  const handleSitelinkPrefixChange = (e) => {
    const { value } = e.target;
    handleInputChange({
      target: {
        id: "customSitelinkPrefix",
        value: value,
      },
    });
  };

  return (
    <div className="grid grid-cols-2 gap-4 mt-6">
      <div>
        <h2 className="text-xl font-bold text-blue-600 mb-4">
          Configurações Avançadas
        </h2>
        <div className="mb-4">
          <label
            className="block text-blue-600 text-sm font-bold mb-2"
            htmlFor="cpcMax"
          >
            CPC Inicial
          </label>
          <input
            id="cpcMax"
            type="number"
            value={campaignData.cpcMax}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            step="0.01"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-blue-600 text-sm font-bold mb-2"
            htmlFor="country"
          >
            País
          </label>
          <select
            id="country"
            value={campaignData.country}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="IN">Índia</option>
            <option value="US">Estados Unidos</option>
            <option value="BR">Brasil</option>
            <option value="MX">México</option>
            <option value="CO">Colômbia</option>
            <option value="FR">França</option>
            <option value="PT">Portugal</option>
            <option value="AR">Argentina</option>
            <option value="GB">Reino Unido</option>
            <option value="CA">Canadá</option>
            <option value="CL">Chile</option>
            <option value="PE">Peru</option>
            <option value="AU">Austrália</option>
            <option value="IE">Irlanda</option>
            <option value="JM">Jamaica</option>
            <option value="UY">Uruguai</option>
            <option value="TT">Trinidad e Tobago</option>
            <option value="GY">Guiana</option>
            <option value="AG">Antígua e Barbuda</option>
            <option value="BB">Barbados</option>
            <option value="BZ">Belize</option>
            <option value="AS">Samoa Americana</option>
            <option value="UM">
              Ilhas Menores Distantes dos Estados Unidos
            </option>
            <option value="CK">Ilhas Cook</option>
            <option value="KW">Kuwait</option>
            <option value="PW">Palau</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-blue-600 text-sm font-bold mb-2"
            htmlFor="conversionGoal"
          >
            Meta de Conversão
          </label>
          <input
            id="conversionGoal"
            type="text"
            value={campaignData.conversionGoal}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-blue-600 text-sm font-bold mb-2"
            htmlFor="startDate"
          >
            Data de Início
          </label>
          <input
            id="startDate"
            type="date"
            value={campaignData.startDate}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>

      <div>
        {/* Agrupando todos os checkboxes juntos */}
        <div className="mb-4">
          <input
            type="checkbox"
            id="noUtm"
            className="mr-2"
            checked={campaignData.noUtm}
            onChange={(e) => handleInputChange(e)}
          />
          <label htmlFor="noUtm" className="text-blue-600 text-sm font-bold">
            Não usar parâmetros de tracking (utm)
          </label>
        </div>

        <div className="mb-4">
          <input
            type="checkbox"
            id="manualUtmCheckbox"
            className="mr-2"
            checked={isManualUtm}
            onChange={() => setIsManualUtm(!isManualUtm)}
            disabled={campaignData.noUtm}
          />
          <label
            htmlFor="manualUtmCheckbox"
            className="text-blue-600 text-sm font-bold"
          >
            Definir UTM manualmente
          </label>
          {isManualUtm && !campaignData.noUtm && (
            <ManualUtmFields
              campaignData={campaignData}
              handleInputChange={handleInputChange}
            />
          )}
        </div>

        <div className="mb-4">
          <input
            type="checkbox"
            id="labelCheckbox"
            className="mr-2"
            checked={isLabel}
            onChange={() => setIsLabel(!isLabel)}
            disabled={campaignData.noUtm}
          />
          <label
            htmlFor="labelCheckbox"
            className="text-blue-600 text-sm font-bold"
          >
            Adicionar Rótulo de Campanha
          </label>
          {isLabel && !campaignData.noUtm && (
            <LabelField
              campaignData={campaignData}
              handleInputChange={handleInputChange}
            />
          )}
        </div>

        <div className="mb-4">
          <input
            type="checkbox"
            id="dynamicSitelinkCheckbox"
            className="mr-2"
            checked={isDynamicSitelink}
            onChange={() => setIsDynamicSitelink(!isDynamicSitelink)}
            disabled={!isManualUtm || campaignData.noUtm}
          />
          <label
            htmlFor="dynamicSitelinkCheckbox"
            className="text-blue-600 text-sm font-bold"
          >
            Prefixo de Sitelink Dinâmico
          </label>
          {isDynamicSitelink && !campaignData.noUtm && (
            <div className="mt-2">
              <label
                className="block text-blue-600 text-sm font-bold mb-2"
                htmlFor="sitelinkPrefix"
              >
                Prefixo do Sitelink
              </label>
              <input
                id="customSitelinkPrefix"
                type="text"
                value={campaignData.customSitelinkPrefix || ""}
                onChange={handleSitelinkPrefixChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required={isDynamicSitelink}
                disabled={!isManualUtm}
              />
              <div className="mt-2 text-gray-500 text-sm italic">
                Exemplo: {campaignData.manualUTM || "utmCampaign"}_
                {campaignData.customSitelinkPrefix || "prefixo"}01
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedSettingsSection;
