import React, { useState } from "react";
import dayjs from "dayjs";
import { FaArrowUp, FaArrowDown, FaRobot, FaUser } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

const LastCpcAdjust = ({ cpcInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cpcAdjustHistory = cpcInfo?.cpcAdjustHistory || [];

  // Ordena o histórico por data e obtém os últimos 100 ajustes
  const sortedHistory = cpcAdjustHistory
    .sort(
      (a, b) => new Date(b.adjustmentDateTime) - new Date(a.adjustmentDateTime)
    )
    .slice(0, 100); // Limita aos últimos 100 ajustes

  const lastAdjust = sortedHistory[0];

  const lastAdjustTime = lastAdjust
    ? dayjs(lastAdjust.adjustmentDateTime).format("DD/MM HH:mm")
    : "N/A";

  const isIncreased = lastAdjust?.newCpc > lastAdjust?.previousCpc;
  const isDecreased = lastAdjust?.newCpc < lastAdjust?.previousCpc;

  const isAutomatic = lastAdjust?.adjustedBy === "kaizenads";

  const tooltipText = lastAdjust
    ? `Ajustado de ${lastAdjust.previousCpc} para ${lastAdjust.newCpc}`
    : "";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <span
        data-tooltip-id="cpcTooltip"
        data-tooltip-content={tooltipText}
        className="flex items-center gap-1 cursor-pointer"
        onClick={openModal} // Abre o modal ao clicar
      >
        {lastAdjustTime}
        {isIncreased && <FaArrowUp className="text-green-500" />}
        {isDecreased && <FaArrowDown className="text-red-500" />}
        {isAutomatic ? (
          <FaRobot className="text-gray-500" title="Ajuste Automático" />
        ) : (
          <FaUser className="text-blue-500" title="Ajuste Manual" />
        )}
      </span>
      <Tooltip id="cpcTooltip" delayShow={200} place="right" />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full">
            <h2 className="text-xl font-bold mb-4">
              Histórico de Ajustes de CPC
            </h2>
            <div className="overflow-y-auto max-h-80">
              <table className="min-w-full table-auto border border-gray-200">
                <thead className="bg-gray-100">
                  <tr className="text-left text-xs">
                    <th className="p-2 border-b">Data</th>
                    <th className="p-2 border-b">Ajustado de</th>
                    <th className="p-2 border-b">Para</th>
                    <th className="p-2 border-b">Tipo de Ajuste</th>
                    <th className="p-2 border-b">Ajustado por</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedHistory.map((adjust, index) => {
                    const isIncreased = adjust.newCpc > adjust.previousCpc;
                    const isDecreased = adjust.newCpc < adjust.previousCpc;
                    const isAutomatic = adjust.adjustedBy === "kaizenads";

                    return (
                      <tr key={index} className="border-b text-xs">
                        <td className="p-2">
                          {dayjs(adjust.adjustmentDateTime).format(
                            "DD/MM HH:mm"
                          )}
                        </td>
                        <td className="p-2">{adjust.previousCpc}</td>
                        <td className="p-2 flex items-center gap-1">
                          {adjust.newCpc}
                          {isIncreased && (
                            <FaArrowUp className="text-green-500" />
                          )}
                          {isDecreased && (
                            <FaArrowDown className="text-red-500" />
                          )}
                        </td>
                        <td className="p-2">{adjust.adjustmentType}</td>
                        <td className="p-2 flex items-center gap-1">
                          {isAutomatic ? (
                            <>
                              <FaRobot className="text-gray-500" />
                              Automático
                            </>
                          ) : (
                            <>
                              <FaUser className="text-blue-500" />
                              Manual
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={closeModal}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LastCpcAdjust;
