import React from "react";
import { Tooltip } from "react-tooltip";

const TextWithTooltip = ({ text, link, slice }) => {
  const truncatedText =
    text.length > 30 && slice ? `${text.slice(0, 30)}...` : text;

  return (
    <>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id={`textTooltip-${text}`}
          data-tooltip-content={text}
          className="cursor-pointer text-blue-500 hover:underline"
        >
          {truncatedText}
        </a>
      ) : (
        <span
          data-tooltip-id={`textTooltip-${text}`}
          data-tooltip-content={text}
          className="cursor-pointer"
        >
          {truncatedText}
        </span>
      )}
      <Tooltip id={`textTooltip-${text}`} delayShow={200} place="right" />
    </>
  );
};

export default TextWithTooltip;
