import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { listAllProjects, createProject } from "../utils/api";
import { jwtDecode } from "jwt-decode";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [manager, setManager] = useState({});
  const [userId, setUserId] = useState(null);
  const [canCreateProject, setCanCreateProject] = useState(false);

  useEffect(() => {
    const checkPermissions = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.scopes && decodedToken.scopes.includes("projects")) {
          setHasPermission(true);
          setUserId(decodedToken.id);
          setCanCreateProject(decodedToken.scopes.includes("gads-integration"));
          fetchProjects();
        }
      }
    };

    checkPermissions();
  }, []);

  const fetchProjects = async () => {
    try {
      const projectsData = await listAllProjects();
      setProjects(projectsData);
    } catch (error) {
      toast.error("Erro ao listar os projetos.");
      console.error("Erro ao listar projetos:", error);
    }
  };

  const handleCreateProject = async () => {
    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const accountId = decodedToken.id;

      const newProjectData = {
        projectType: projectName,
        accountId: accountId,
      };

      const response = await createProject(newProjectData);
      if (response && response.id) {
        setIsModalOpen(false); // Fecha o modal após a criação do projeto
        fetchProjects(); // Recarrega a lista de projetos após a criação
        toast.success("Projeto criado com sucesso!");
      } else {
        throw new Error(
          "Erro ao criar o projeto. Verifique os dados e tente novamente."
        );
      }
    } catch (error) {
      toast.error("Erro ao criar um novo projeto.");
      console.error("Erro ao criar projeto:", error);
    }
  };

  const handleProjectClick = (projectId) => {
    window.location.href = `/project/${projectId}`; // Redireciona para o dashboard dinâmico do projeto
  };

  const renderProjectRole = (project) => {
    if (project.accountId === userId) {
      return <span className="text-green-600 font-semibold">Dono</span>;
    } else if (project.trafficManagersIds?.includes(userId)) {
      return <span className="text-blue-600 font-semibold">Gerente</span>;
    }
    return <span className="text-gray-600">Visualizador</span>;
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
        {hasPermission ? (
          <div>
            <h1 className="text-2xl font-bold mb-6 text-gray-700">
              Meus Projetos
            </h1>
            {projects.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {projects.map((project) => (
                  <div
                    key={project.id}
                    className="bg-gray-200 p-4 rounded shadow cursor-pointer hover:bg-gray-300 transition-colors duration-200"
                    onClick={() => handleProjectClick(project.id)}
                  >
                    <h2 className="text-xl font-semibold text-gray-800">
                      {project.projectType}
                    </h2>
                    <p className="text-gray-600">ID: {project.id}</p>
                    <p className="text-gray-600">
                      Status: {renderProjectRole(project)}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center">
                <p className="text-gray-600 mb-4">
                  Você ainda não possui projetos. Crie seu primeiro projeto!
                </p>
                {canCreateProject && (
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200"
                  >
                    Criar Primeiro Projeto
                  </button>
                )}
              </div>
            )}
            {projects.length > 0 && canCreateProject && (
              <div className="text-center mt-6">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors duration-200"
                >
                  Criar Mais Um Projeto
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-red-600">Acesso Negado</h2>
            <p className="text-gray-600">
              Você não tem permissão para gerenciar projetos. Por favor, entre
              em contato com o administrador.
            </p>
          </div>
        )}
      </div>

      {/* Modal para criação de projeto */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Criar Novo Projeto</h2>
            <input
              type="text"
              placeholder="Nome do Projeto"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition-colors duration-200"
              >
                Cancelar
              </button>
              <button
                onClick={handleCreateProject}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200"
              >
                Criar Projeto
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
