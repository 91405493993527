import React from "react";

const KeywordSection = ({ campaignData, handleInputChange }) => (
  <div>
    <h2 className="text-xl font-bold text-blue-600 mb-4">
      Configurações de Palavras-Chave
    </h2>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="finalUrl"
      >
        URL (sem UTM)
      </label>
      <input
        id="finalUrl"
        type="url"
        value={campaignData.finalUrl}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        required
      />
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="keywords"
      >
        Lista de Palavras-Chave
      </label>
      <textarea
        id="keywords"
        value={campaignData.keywords}
        onChange={handleInputChange}
        rows="5"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        required
      ></textarea>
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="blacklist_keywords"
      >
        Palavras que não ser usadas no anúncio (opcional)
      </label>
      <textarea
        id="blacklist_keywords"
        value={campaignData.blacklist_keywords}
        onChange={handleInputChange}
        rows="5"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      ></textarea>
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="cta_for_headline_1"
      >
        CTA para Headline 1
      </label>
      <input
        id="cta_for_headline_1"
        type="text"
        value={campaignData.cta_for_headline_1}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        maxLength="30"
        required
      />
    </div>
  </div>
);

export default KeywordSection;
