import React, { useState, useEffect } from "react";
import {
  FaExternalLinkAlt,
  FaInfoCircle,
  FaPencilAlt,
  FaCheck,
  FaCog,
  FaSearch,
  FaLink,
} from "react-icons/fa";
import {
  getCampaignReportHistory,
  modifyAdGroupBid,
  getRealTimeEvents,
} from "../utils/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import CpcEditor from "./CpcEditor";
import LastCpcAdjust from "./LastAdjustCpc";
import DisplayEventCount from "./analytics/DisplayEventCount";
import TextWithTooltip from "./TextWithTooltip";

dayjs.extend(utc);
dayjs.extend(timezone);

const LOCAL_STORAGE_KEY = "tableConfig";

const defaultVisibleColumns = {
  campaignName: true,
  adGroupName: true,
  utm: true,
  cpcAdjustTime: true,
  biddingStrategy: true,
  cpc: true,
  roi: true,
  googleAdsCost: true,
  roiRevenue: true,
  adManagerRevenueBRL: true,
  adManagerRevenueUSD: true,
  googleAdsClicks: true,
  conversions: true,
  impressions: true,
  adManagerClicks: true,
  adManagerImpressions: true,
  gaEvents: true,
  createdAt: true,
};

const CampaignPerformanceTable = ({ reports, singleCampaign = false }) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableCPC, setEditableCPC] = useState(null);
  const [editedCPCValue, setEditedCPCValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gaEventList, setGaEventList] = useState([]);
  const [showConfig, setShowConfig] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);
  const [truncateText, setTruncateText] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]); // Estado para linhas selecionadas

  // Função para carregar configurações do localStorage e mesclar com as colunas padrão
  const loadConfigFromLocalStorage = () => {
    const savedConfig = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedConfig) {
      const parsedConfig = JSON.parse(savedConfig);

      // Mesclar campos existentes no localStorage com os campos padrão
      const updatedColumns = {
        ...defaultVisibleColumns,
        ...parsedConfig.visibleColumns,
      };

      // Garantir que todos os campos padrão estejam presentes
      setVisibleColumns(updatedColumns);
      setTruncateText(parsedConfig.truncateText || false);
    } else {
      setVisibleColumns(defaultVisibleColumns);
    }
  };

  // Função para salvar as configurações no localStorage
  const saveConfigToLocalStorage = (config) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(config));
  };

  useEffect(() => {
    loadConfigFromLocalStorage(); // Carregar as configurações ao montar o componente
  }, []);

  const toggleColumnVisibility = (column) => {
    const updatedColumns = {
      ...visibleColumns,
      [column]: !visibleColumns[column],
    };
    setVisibleColumns(updatedColumns);
    saveConfigToLocalStorage({ visibleColumns: updatedColumns, truncateText });
  };

  const toggleTruncateText = () => {
    const updatedTruncateText = !truncateText;
    setTruncateText(updatedTruncateText);
    saveConfigToLocalStorage({
      visibleColumns,
      truncateText: updatedTruncateText,
    });
  };

  const projectId = reports.length > 0 ? reports[0].projectId : undefined;

  useEffect(() => {
    let intervalId;

    if (!singleCampaign && projectId) {
      const fetchGaEvents = async () => {
        try {
          const result = await getRealTimeEvents(projectId);
          if (result.success) {
            setGaEventList(result.ga4events || []);
          } else {
            console.error("Failed to fetch GA events:", result.error);
          }
        } catch (error) {
          console.error("Error fetching GA events:", error);
        }
      };

      fetchGaEvents();
      intervalId = setInterval(fetchGaEvents, 60000);
    }

    return () => clearInterval(intervalId);
  }, [singleCampaign, projectId]);

  const openModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCampaign(null);
  };

  const handleEditCPC = (campaignId, adGroupId, currentValue) => {
    setEditableCPC(campaignId);
    setEditedCPCValue(currentValue);
  };

  const handleSaveCPC = async (
    campaignId,
    adGroupId,
    projectId,
    biddingStrategyType,
    currentValue
  ) => {
    if (parseFloat(editedCPCValue) === parseFloat(currentValue)) {
      toast.error("O valor inserido é o mesmo que o atual.");
      return;
    }

    setIsSubmitting(true);

    const adjustType =
      biddingStrategyType === 10 ? "target_cpa_micros" : "cpc_bid_micros";

    const response = await modifyAdGroupBid({
      projectId,
      campaignId,
      adGroupId,
      adjustValue: parseFloat(editedCPCValue),
      adjustType,
    });

    if (response.success) {
      toast.success(response.message || "CPC atualizado com sucesso!");

      const updatedReports = reports.map((report) => {
        if (
          report.campaignId === campaignId &&
          report.adGroupId === adGroupId
        ) {
          report.currentBid = editedCPCValue;
        }
        return report;
      });

      setEditableCPC(null);
    } else {
      toast.error(response.message || "Falha ao atualizar o CPC.");
    }

    setIsSubmitting(false);
  };

  // Função para alternar seleção de linha
  const toggleRowSelection = (campaignId, event) => {
    // Verifica se Ctrl ou Command (metaKey) está pressionada
    if (event.ctrlKey || event.metaKey) {
      // Se a tecla Ctrl/Command estiver pressionada, alterna a seleção da linha
      if (selectedRows.includes(campaignId)) {
        setSelectedRows(selectedRows.filter((id) => id !== campaignId));
      } else {
        setSelectedRows([...selectedRows, campaignId]);
      }
    } else {
      // Se Ctrl/Command não estiver pressionada, seleciona apenas a linha clicada
      if (selectedRows.includes(campaignId)) {
        setSelectedRows([]); // Se já estiver selecionada, desmarca tudo
      } else {
        setSelectedRows([campaignId]); // Seleciona apenas a linha atual
      }
    }
  };

  const isRowSelected = (campaignId) => {
    return selectedRows.includes(campaignId);
  };

  const filteredReports = reports.filter((report) => {
    const query = searchQuery.toLowerCase();
    return (
      report.campaignName?.toLowerCase().includes(query) ||
      "" ||
      report.adGroupName?.toLowerCase().includes(query) ||
      "" ||
      report.utm?.toLowerCase().includes(query) ||
      ""
    );
  });

  const sortedReports = [...filteredReports].sort((a, b) => {
    if (singleCampaign) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else {
      return b.googleAdsCost - a.googleAdsCost;
    }
  });

  return (
    <div className="overflow-x-auto max-w-full">
      <div className="overflow-y-auto max-h-[500px]">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button
              className="m-2 p-1 bg-gray-200 rounded hover:bg-gray-300 text-sm text-gray-600 flex items-center"
              onClick={() => setShowConfig(!showConfig)}
            >
              <FaCog className="mr-2" />
              Configurar Tabela
            </button>
          </div>
          <div className="flex items-center">
            <div className="flex items-center bg-gray-100 rounded p-1">
              <FaSearch className="text-gray-500 mr-2" />
              <input
                type="text"
                placeholder="Pesquisar..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="bg-transparent border-none focus:outline-none text-sm"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <span className="m-2 text-xs">
              <strong>Horário do relatório: </strong>
              {dayjs(reports[0].createdAt).format("DD/MM HH:mm")}
            </span>
          </div>
        </div>

        {showConfig && (
          <div className="m-2 p-2 bg-gray-100 border border-gray-300 rounded">
            <strong>Selecionar colunas:</strong>
            <div className="flex flex-wrap gap-4 mt-2">
              {Object.keys(visibleColumns).map((column) => (
                <label key={column} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={visibleColumns[column]}
                    onChange={() => toggleColumnVisibility(column)}
                    className="mr-2"
                  />
                  {column}
                </label>
              ))}
            </div>
            <label className="flex items-center mt-4">
              <input
                type="checkbox"
                checked={truncateText}
                onChange={toggleTruncateText}
                className="mr-2"
              />
              Truncar Nome da Campanha e Nome do Grupo para 30 caracteres
            </label>
          </div>
        )}

        <table className="min-w-full table-fixed bg-white border border-gray-200 rounded-lg">
          <thead className="bg-gray-100 sticky top-0 z-10">
            <tr className="text-left text-xs">
              <th className="p-2 border-b w-12 whitespace-nowrap">
                <FaInfoCircle className="text-gray-500" />
              </th>
              {visibleColumns.campaignName && (
                <th className="p-2 border-b w-40 whitespace-nowrap">
                  Nome da Campanha
                </th>
              )}
              {visibleColumns.adGroupName && (
                <th className="p-2 border-b w-40 whitespace-nowrap">
                  Nome do Grupo
                </th>
              )}
              {visibleColumns.utm && (
                <th className="p-2 border-b w-40 whitespace-nowrap">UTM</th>
              )}
              {singleCampaign && visibleColumns.createdAt && (
                <th className="p-2 border-b w-40 whitespace-nowrap">Data</th>
              )}
              {!singleCampaign && visibleColumns.gaEvents && (
                <th className="p-2 border-b w-32 whitespace-nowrap">
                  G-Analytics
                </th>
              )}
              {visibleColumns.cpcAdjustTime && (
                <th className="p-2 border-b w-32 whitespace-nowrap">
                  Último ajuste de CPC
                </th>
              )}
              {visibleColumns.biddingStrategy && (
                <th className="p-2 border-b w-32 whitespace-nowrap">
                  Estratégia de Lances
                </th>
              )}
              {visibleColumns.cpc && (
                <th className="p-2 border-b w-28 whitespace-nowrap">
                  CPC (R$)
                </th>
              )}
              {visibleColumns.roi && (
                <th className="p-2 border-b w-28 whitespace-nowrap">ROI (%)</th>
              )}
              {visibleColumns.googleAdsCost && (
                <th className="p-2 border-b w-32 whitespace-nowrap">
                  Total Gasto
                </th>
              )}
              {visibleColumns.roiRevenue && (
                <th className="p-2 border-b w-32 whitespace-nowrap">Lucro</th>
              )}
              {visibleColumns.adManagerRevenueBRL && (
                <th className="p-2 border-b w-36 whitespace-nowrap">
                  Total Ganho (BRL)
                </th>
              )}
              {visibleColumns.adManagerRevenueUSD && (
                <th className="p-2 border-b w-36 whitespace-nowrap">
                  Total Ganho (USD)
                </th>
              )}
              {visibleColumns.googleAdsClicks && (
                <th className="p-2 border-b w-32 whitespace-nowrap">
                  Google Ads Clicks
                </th>
              )}
              {visibleColumns.conversions && (
                <th className="p-2 border-b w-40 whitespace-nowrap">
                  Google Ads Conversions
                </th>
              )}
              {visibleColumns.impressions && (
                <th className="p-2 border-b w-40 whitespace-nowrap">
                  Google Ads Impressions
                </th>
              )}
              {visibleColumns.adManagerClicks && (
                <th className="p-2 border-b w-36 whitespace-nowrap">
                  Ad Manager Clicks
                </th>
              )}
              {visibleColumns.adManagerImpressions && (
                <th className="p-2 border-b w-40 whitespace-nowrap">
                  Ad Manager Impressions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedReports.map((report) => {
              const {
                campaignId,
                adGroupId,
                customerId,
                roiPercentage,
                roiRevenue,
                googleAdsCost,
                adManagerRevenueBRL,
                adManagerRevenueUSD,
                googleAdsClicks,
                googleAdsImpressions,
                adManagerClicks,
                adManagerImpressions,
                cpcAdjustTime,
                utm,
                createdAt,
              } = report;

              const bid = report.campaignDetails.bidding_strategy_type;

              let biddingStrategy;

              if (bid === "cpc_bid_micros") {
                biddingStrategy = "CPC Manual";
              } else if (bid === "enhanced_cpc") {
                biddingStrategy = "CPC Otimizado";
              } else if (bid === "target_cpa_micros") {
                biddingStrategy = "CPA Desejado";
              } else if (bid === "target_roas_micros") {
                biddingStrategy = "ROAS Desejado";
              } else if (bid === "cpm_bid_micros") {
                biddingStrategy = "CPM Manual";
              } else if (bid === "cpc_bid_ceiling_micros") {
                biddingStrategy = "Maximizar cliques";
              } else if (bid === "effective_target_cpa_micros") {
                biddingStrategy = "Maximizar Conversões";
              } else if (bid === "commission_rate_micros") {
                biddingStrategy = "Comissão";
              } else if (bid === "maximize_conversions") {
                biddingStrategy = "Maximizar Conversões";
              } else if (bid === "target_spend") {
                biddingStrategy = "Gasto Desejado";
              } else {
                biddingStrategy = "CPC Manual"; // Padrão caso não corresponda a nenhum tipo
              }

              let cpcInReais = report.currentBid ? report.currentBid : "∞";
              const formattedCpcAdjustTime = cpcAdjustTime
                ? dayjs(cpcAdjustTime)
                    .tz("America/Sao_Paulo")
                    .format("DD/MM HH:mm")
                : "N/A";

              const roiStyle =
                roiPercentage > 0
                  ? "text-green-500"
                  : roiPercentage < 0
                  ? "text-red-500"
                  : "text-black";

              const lucroStyle =
                roiRevenue > 0
                  ? "text-green-500"
                  : roiRevenue < 0
                  ? "text-red-500"
                  : "text-black";

              const formattedCreatedAt = dayjs(createdAt)
                .tz("America/Sao_Paulo")
                .format("DD/MM HH:mm");

              const rowClass = isRowSelected(campaignId)
                ? "bg-gray-300" // Cor mais forte para linha selecionada
                : "hover:bg-gray-100"; // Cor de hover normal

              return (
                <tr
                  key={campaignId}
                  className={`border-b text-xs transition-colors ${rowClass}`}
                  onClick={(e) => toggleRowSelection(campaignId, e)} // Passar o evento de clique
                >
                  <td className="p-2 text-gray-800 whitespace-nowrap">
                    <FaInfoCircle
                      className="text-gray-500 hover:text-gray-700 cursor-pointer"
                      onClick={() => openModal(report)}
                    />
                  </td>
                  {visibleColumns.campaignName && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      <TextWithTooltip
                        text={report.campaignName}
                        slice={truncateText}
                      />
                    </td>
                  )}
                  {visibleColumns.adGroupName && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      <TextWithTooltip
                        text={report.adGroupName}
                        slice={truncateText}
                      />
                    </td>
                  )}
                  {visibleColumns.utm && (
                    <td className="p-2 text-gray-800 whitespace-nowrap flex items-center gap-2">
                      <a
                        href={`https://ads.google.com/aw/adgroups?campaignId=${campaignId}&__c=${customerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 text-blue-500 hover:underline"
                      >
                        {utm} <FaExternalLinkAlt />
                      </a>
                      {report.campaignDetails.adGroups[0]?.ads[0]
                        ?.final_urls[0] && (
                        <a
                          href={`${report.campaignDetails.adGroups[0]?.ads[0]?.final_urls[0]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 text-blue-500 hover:underline"
                        >
                          <FaLink />
                        </a>
                      )}
                    </td>
                  )}
                  {singleCampaign && visibleColumns.createdAt && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {formattedCreatedAt}
                    </td>
                  )}
                  {!singleCampaign && visibleColumns.gaEvents && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      <DisplayEventCount
                        eventName={utm}
                        eventList={gaEventList}
                      />
                    </td>
                  )}
                  {visibleColumns.cpcAdjustTime && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      <LastCpcAdjust cpcInfo={report.cpcInfo} />
                    </td>
                  )}
                  {visibleColumns.biddingStrategy && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {biddingStrategy}
                    </td>
                  )}
                  {visibleColumns.cpc && (
                    <td className="p-2 text-gray-800 whitespace-nowrap flex items-center gap-2">
                      {editableCPC === campaignId ? (
                        <input
                          type="text"
                          value={editedCPCValue}
                          onChange={(e) => setEditedCPCValue(e.target.value)}
                          className="border border-gray-300 p-1 text-xs rounded"
                          disabled={isSubmitting}
                        />
                      ) : (
                        `R$ ${cpcInReais}`
                      )}
                      {!singleCampaign && editableCPC !== campaignId && (
                        <FaPencilAlt
                          className="text-gray-500 hover:text-gray-700 cursor-pointer"
                          onClick={() =>
                            handleEditCPC(campaignId, adGroupId, cpcInReais)
                          }
                        />
                      )}
                      {editableCPC === campaignId && (
                        <FaCheck
                          className={`${
                            isSubmitting ? "text-gray-400" : "text-green-500"
                          } cursor-pointer`}
                          onClick={() =>
                            !isSubmitting &&
                            handleSaveCPC(
                              campaignId,
                              adGroupId,
                              report.projectId,
                              bid,
                              cpcInReais
                            )
                          }
                        />
                      )}
                    </td>
                  )}
                  {visibleColumns.roi && (
                    <td
                      className={`p-2 font-semibold ${roiStyle} whitespace-nowrap`}
                    >
                      {roiPercentage?.toFixed(2)}%
                    </td>
                  )}
                  {visibleColumns.googleAdsCost && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      R$ {googleAdsCost?.toFixed(2)}
                    </td>
                  )}
                  {visibleColumns.roiRevenue && (
                    <td className={`p-2 ${lucroStyle} whitespace-nowrap`}>
                      R$ {roiRevenue?.toFixed(2)}
                    </td>
                  )}
                  {visibleColumns.adManagerRevenueBRL && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      R${" "}
                      {adManagerRevenueBRL !== null &&
                      adManagerRevenueBRL !== undefined
                        ? adManagerRevenueBRL.toFixed(2)
                        : "N/A"}
                    </td>
                  )}
                  {visibleColumns.adManagerRevenueUSD && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      $
                      {adManagerRevenueUSD !== null &&
                      adManagerRevenueUSD !== undefined
                        ? adManagerRevenueUSD.toFixed(2)
                        : "N/A"}
                    </td>
                  )}
                  {visibleColumns.googleAdsClicks && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {googleAdsClicks}
                    </td>
                  )}
                  {visibleColumns.conversions && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {report.campaignDetails.metrics?.conversions?.toFixed(
                        1
                      ) || "N/A"}
                    </td>
                  )}
                  {visibleColumns.impressions && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {googleAdsImpressions}
                    </td>
                  )}
                  {visibleColumns.adManagerClicks && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {adManagerClicks}
                    </td>
                  )}
                  {visibleColumns.adManagerImpressions && (
                    <td className="p-2 text-gray-800 whitespace-nowrap">
                      {adManagerImpressions}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {isModalOpen && selectedCampaign && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
          <div className="bg-white p-6 sm:p-8 rounded-lg w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl shadow-lg">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 sm:mb-6">
              Detalhes da Campanha:{" "}
              {selectedCampaign.campaignDetails.campaign?.name}
            </h2>
            <div className="text-gray-600 space-y-4">
              <div className="flex justify-between">
                <span className="font-medium">ID da Campanha:</span>
                <span>{selectedCampaign.campaignId}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Nome da Campanha:</span>
                <span>{selectedCampaign.campaignDetails.name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">ID do Grupo:</span>
                <span>{selectedCampaign.adGroupId}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Nome do Grupo:</span>
                <span>{selectedCampaign.adGroupName}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">UTM:</span>
                <span>{selectedCampaign.utm}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">ROI (%):</span>
                <span>{selectedCampaign.roiPercentage?.toFixed(2)}%</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Lucro:</span>
                <span>R$ {selectedCampaign.roiRevenue?.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Gasto:</span>
                <span>R$ {selectedCampaign.googleAdsCost?.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Ganho (BRL):</span>
                <span>
                  R$ {selectedCampaign.adManagerRevenueBRL?.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Ganho (USD):</span>
                <span>${selectedCampaign.adManagerRevenueUSD?.toFixed(2)}</span>
              </div>
            </div>

            <CpcEditor
              campaignId={selectedCampaign.campaignId}
              adGroupId={selectedCampaign.adGroupId}
              onClose={closeModal}
            />

            <div className="mt-6 flex justify-between">
              <Link
                to={`/project/${selectedCampaign.projectId}/performance/${selectedCampaign.campaignId}/${selectedCampaign.adGroupId}`}
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded hover:bg-gray-200 transition"
                onClick={closeModal}
              >
                Ver Histórico Completo
              </Link>
              <button
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded hover:bg-gray-200 transition"
                onClick={closeModal}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignPerformanceTable;
