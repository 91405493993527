import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  getProjectById,
  getLastReport,
  addOrUpdateProjectConfig,
  getAggregatedReport,
} from "../utils/api";
import CampaignPerformanceTable from "../components/CampaignPerformanceTable";
import toast, { Toaster } from "react-hot-toast";

const ProjectPerformance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [reports, setReports] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reportRange, setReportRange] = useState("today");
  const [cpcBotEnabled, setCpcBotEnabled] = useState(false);
  const [projectPaused, setProjectPaused] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token não encontrado. Por favor, faça login.");
        }

        const decodedToken = jwtDecode(token);

        if (!decodedToken.scopes || !decodedToken.scopes.includes("projects")) {
          throw new Error("Você não tem permissão para acessar esta página.");
        }

        const accountId = decodedToken.id;
        const projectData = await getProjectById(id);

        if (!projectData) {
          throw new Error("Projeto não encontrado.");
        }

        // Verifica se o usuário é o dono ou está na lista de managers (trafficManagersIds)
        const isOwner = projectData.accountId === accountId;
        const isManager = projectData.trafficManagersIds?.includes(accountId);

        if (!isOwner && !isManager) {
          throw new Error("Você não está autorizado a acessar este projeto.");
        }

        setIsAuthorized(true);
        setProjectDetails(projectData);

        // Definindo o estado do bot de CPC e o estado de pausa de projeto com base nas configs
        setCpcBotEnabled(!Boolean(projectData.config?.cpcBotEnable ?? false));
        setProjectPaused(!Boolean(projectData.config?.runReports ?? false));

        // Buscar os últimos relatórios da API
        const lastReportData = await getLastReport(id);
        setReports(lastReportData);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
        setTimeout(() => {
          navigate("/"); // Redireciona para a página inicial após exibir o erro
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthorization();
  }, [id, navigate]);

  const handleReportRangeChange = async (range) => {
    setReportRange(range);
    setIsLoading(true); // Exibir spinner durante a nova requisição

    try {
      if (range !== "today") {
        const aggregatedReport = await getAggregatedReport(id, range);
        const aggregatedReportValidate = aggregatedReport.filter(
          (cp) => cp.campaignName
        );
        setReports(aggregatedReportValidate);
      } else {
        const lastReportData = await getLastReport(id);
        setReports(lastReportData);
      }
    } catch (error) {
      toast.error("Erro ao buscar relatório: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCpcBot = async () => {
    const newCpcBotEnabled = !cpcBotEnabled;
    setCpcBotEnabled(newCpcBotEnabled || false);

    try {
      await addOrUpdateProjectConfig(
        id,
        "cpcBotEnable",
        Boolean(!newCpcBotEnabled)
      );
      toast.success("Configuração de Bot de CPC atualizada com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const toggleProjectPaused = async () => {
    const newProjectPaused = !projectPaused;
    setProjectPaused(newProjectPaused);

    try {
      await addOrUpdateProjectConfig(
        id,
        "runReports",
        Boolean(!newProjectPaused)
      );
      toast.success("Configuração de Pausa de Projeto atualizada com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const calculateMetrics = () => {
    let totalLucro = 0;
    let totalGasto = 0;
    let totalGanho = 0;
    let campanhasPositivas = 0;
    let campanhasNegativas = 0;

    reports.forEach((report) => {
      totalLucro += report.roiRevenue || 0;
      totalGasto += report.googleAdsCost || 0;
      totalGanho += report.adManagerRevenueBRL || 0;

      if (report.roiPercentage > 0) {
        campanhasPositivas += 1;
      } else if (report.roiPercentage < 0) {
        campanhasNegativas += 1;
      }
    });

    // Calcula a média de ROI com base no total de ganho e gasto
    const mediaROI =
      totalGasto > 0 ? ((totalGanho - totalGasto) / totalGasto) * 100 : 0;

    return {
      totalLucro,
      totalGasto,
      totalGanho,
      mediaROI,
      campanhasPositivas,
      campanhasNegativas,
    };
  };

  const {
    totalLucro,
    totalGasto,
    totalGanho,
    mediaROI,
    campanhasPositivas,
    campanhasNegativas,
  } = calculateMetrics();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center justify-center space-x-2">
          <div className="w-8 h-8 border-4 border-blue-500 border-dotted rounded-full animate-spin"></div>
          <p className="text-gray-600">Carregando...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-600">
          Você não está autorizado a acessar esta página.
        </p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen max-w-7xl mx-auto">
      <Toaster position="top-right" reverseOrder={false} />

      {projectDetails && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Detalhes do Projeto
          </h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-4 rounded-lg shadow">
              <p className="text-xs font-semibold text-gray-500">
                Tipo do Projeto
              </p>
              <p className="text-lg font-bold text-gray-800">
                {projectDetails.projectType}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <p className="text-xs font-semibold text-gray-500">
                Google Ads ID
              </p>
              <p className="text-lg font-bold text-gray-800">
                {projectDetails.googleAdsId.join(", ")}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <p className="text-xs font-semibold text-gray-500">
                AdManager ID
              </p>
              <p className="text-lg font-bold text-gray-800">
                {projectDetails.admanagerId}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <p className="text-xs font-semibold text-gray-500">
                GA4 Property ID
              </p>
              <p className="text-lg font-bold text-gray-800">
                {projectDetails.ga4PropertyId}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <p className="text-xs font-semibold text-gray-500">Domain</p>
              <p className="text-lg font-bold text-gray-800">
                {projectDetails.domain}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <p className="text-xs font-semibold text-gray-500">
                Dollar Depreciation
              </p>
              <p className="text-lg font-bold text-gray-800">
                {projectDetails.config?.DollarDepreciation || "N/A"}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="mb-8 text-center">
        <p className="text-sm text-gray-600">
          Selecione o período do relatório:
        </p>
        <div className="flex justify-center space-x-2 mt-2">
          {[
            "today",
            "yesterday",
            "last-7-days",
            "last-14-days",
            "last-30-days",
            "last-90-days",
          ].map((range) => (
            <button
              key={range}
              className={`px-3 py-1 rounded-md border ${
                reportRange === range
                  ? "bg-blue-500 text-white border-transparent"
                  : "bg-white text-gray-700 border-gray-300 hover:bg-gray-200"
              }`}
              onClick={() => handleReportRangeChange(range)}
            >
              {range === "today"
                ? "Hoje"
                : range === "yesterday"
                ? "Ontem"
                : range === "last-7-days"
                ? "Últimos 7 dias"
                : range === "last-14-days"
                ? "Últimos 14 dias"
                : range === "last-30-days"
                ? "Últimos 30 dias"
                : "Últimos 90 dias"}
            </button>
          ))}
        </div>
      </div>

      <div className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-6">
        <div className="bg-blue-100 p-4 rounded-lg shadow">
          <p className="text-xs font-semibold text-blue-700">Lucro Total</p>
          <p className="text-lg font-bold text-blue-900">
            R$ {totalLucro.toFixed(2)}
          </p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow">
          <p className="text-xs font-semibold text-green-700">Total Gasto</p>
          <p className="text-lg font-bold text-green-900">
            R$ {totalGasto.toFixed(2)}
          </p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg shadow">
          <p className="text-xs font-semibold text-yellow-700">Total Ganho</p>
          <p className="text-lg font-bold text-yellow-900">
            R$ {totalGanho.toFixed(2)}
          </p>
        </div>
        <div className="bg-indigo-100 p-4 rounded-lg shadow">
          <p className="text-xs font-semibold text-indigo-700">Média % ROI</p>
          <p className="text-lg font-bold text-indigo-900">
            {mediaROI.toFixed(2)}%
          </p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow">
          <p className="text-xs font-semibold text-green-700">
            Campanhas com ROI Positivo
          </p>
          <p className="text-lg font-bold text-green-900">
            {campanhasPositivas}
          </p>
        </div>
        <div className="bg-red-100 p-4 rounded-lg shadow">
          <p className="text-xs font-semibold text-red-700">
            Campanhas com ROI Negativo
          </p>
          <p className="text-lg font-bold text-red-900">{campanhasNegativas}</p>
        </div>
      </div>

      <div className="mb-8">
        <div className="flex items-center space-x-4">
          <div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                checked={cpcBotEnabled}
                onChange={toggleCpcBot}
              />
              <span className="ml-2 text-gray-700">Desligar Bot de CPC</span>
            </label>
            <p className="text-xs text-gray-500 mt-1">
              Desativa temporariamente os ajustes automáticos de CPC.
            </p>
          </div>
          <div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-red-600"
                checked={projectPaused}
                onChange={toggleProjectPaused}
              />
              <span className="ml-2 text-gray-700">Pausar Monitoramento</span>
            </label>
            <p className="text-xs text-gray-500 mt-1">
              Pausa o acompanhamento de performance deste projeto.
            </p>
          </div>
        </div>
      </div>

      <CampaignPerformanceTable reports={reports} />

      <p className="mt-4 text-xs text-gray-500 text-center">
        Os dados são armazenados em nosso servidor por apenas 90 dias. Após esse
        período, eles são permanentemente destruídos.
      </p>
    </div>
  );
};

export default ProjectPerformance;
