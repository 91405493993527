import React from "react";

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
      <h1 className="text-4xl font-extrabold text-center text-blue-700 mb-8">
        Termos de Uso da Plataforma KaizenADS
      </h1>

      <div className="space-y-10 text-justify leading-relaxed">
        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            1. Introdução
          </h2>
          <p className="text-gray-800">
            Bem-vindo à KaizenADS! Estes Termos de Uso regulam a utilização da
            nossa plataforma e dos serviços oferecidos. Ao utilizar nossos
            serviços, você concorda com todos os termos estabelecidos abaixo.
            Leia atentamente antes de usar a plataforma.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            2. Definições
          </h2>
          <p className="text-gray-800">
            Para facilitar o entendimento destes Termos de Uso, as seguintes
            definições serão utilizadas:
          </p>
          <ul className="list-disc ml-6 text-gray-800">
            <li>
              <strong>Plataforma:</strong> Refere-se à plataforma online
              KaizenADS, acessível por meio da URL{" "}
              <a
                href="https://kaizenads.io"
                className="text-blue-600 underline"
              >
                https://kaizenads.io
              </a>
              .
            </li>
            <li>
              <strong>Usuário:</strong> Qualquer pessoa que acesse ou use os
              serviços da KaizenADS.
            </li>
            <li>
              <strong>Serviços:</strong> Funcionalidades oferecidas pela
              KaizenADS para criação e gerenciamento de campanhas publicitárias.
            </li>
            <li>
              <strong>Dados Pessoais:</strong> Informações que identificam ou
              podem identificar uma pessoa física, conforme definido na LGPD.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            3. Cadastro
          </h2>
          <p className="text-gray-800">
            Para utilizar as funcionalidades da Plataforma KaizenADS, o Usuário
            deve realizar um cadastro, fornecendo informações verdadeiras e
            atualizadas. O Usuário é responsável por manter a confidencialidade
            de sua senha e login, e por todas as atividades realizadas em sua
            conta.
          </p>
          <p className="text-gray-800">
            A KaizenADS reserva-se o direito de solicitar documentos adicionais
            para verificar a autenticidade das informações fornecidas e pode
            suspender ou cancelar o cadastro em caso de violação dos Termos de
            Uso.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            4. Regras de Utilização
          </h2>
          <p className="text-gray-800">
            O Usuário concorda em utilizar a Plataforma exclusivamente para fins
            lícitos e em conformidade com a legislação aplicável. É proibido:
          </p>
          <ul className="list-disc ml-6 text-gray-800">
            <li>
              Utilizar a Plataforma para transmitir qualquer material ilegal,
              ofensivo ou que infrinja os direitos de terceiros.
            </li>
            <li>
              Compartilhar login e senha com terceiros ou permitir o acesso não
              autorizado à sua conta.
            </li>
            <li>
              Utilizar a Plataforma para realizar práticas que possam
              comprometer a segurança da KaizenADS ou de outros Usuários.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            5. Serviços
          </h2>
          <p className="text-gray-800">
            A KaizenADS oferece serviços que permitem a criação e gerenciamento
            de campanhas publicitárias utilizando inteligência artificial (IA).
            Ao utilizar os serviços, o Usuário concede à KaizenADS o direito de
            acessar e processar os dados necessários para fornecer os serviços
            contratados.
          </p>
          <p className="text-gray-800">
            Todos os dados processados pela KaizenADS são criptografados para
            garantir a segurança e a privacidade do Usuário. No entanto, durante
            o tempo de execução da campanha, o servidor da KaizenADS precisa
            acessar esses dados para poder gerar os textos com IA. Esse acesso é
            temporário e restrito ao período necessário para a criação e
            personalização dos conteúdos da campanha.
          </p>
          <p className="text-gray-800">
            Os serviços são fornecidos "como estão" e "conforme disponíveis",
            sem garantias de qualquer tipo, expressas ou implícitas, incluindo,
            sem limitação, garantias de comercialização, adequação a um fim
            específico e não violação.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            6. Criptografia e Segurança de Dados
          </h2>
          <p className="text-gray-800">
            Levamos a segurança dos seus dados muito a sério. Todas as
            informações enviadas para a nossa plataforma são criptografadas de
            ponta a ponta. Utilizamos as mais recentes tecnologias de
            criptografia para proteger seus dados em repouso e em trânsito, de
            acordo com as melhores práticas e exigências da LGPD.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            7. Privacidade e Proteção de Dados
          </h2>
          <p className="text-gray-800">
            Nossa Política de Privacidade detalha como coletamos, utilizamos e
            protegemos suas informações pessoais. Ao usar a KaizenADS, você
            concorda com a coleta e uso de informações conforme a nossa
            política, em conformidade com a Lei Geral de Proteção de Dados
            (LGPD) e outras regulamentações aplicáveis.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            8. Propriedade Intelectual
          </h2>
          <p className="text-gray-800">
            Todo o conteúdo disponível na Plataforma, incluindo textos,
            gráficos, logotipos, ícones, imagens, clipes de áudio, downloads
            digitais e compilações de dados, é de propriedade exclusiva da
            KaizenADS ou de seus fornecedores de conteúdo e é protegido por leis
            de direitos autorais, marcas registradas e outras leis de
            propriedade intelectual.
          </p>
          <p className="text-gray-800">
            O Usuário concorda em não copiar, reproduzir, modificar, criar obras
            derivadas, distribuir, exibir publicamente ou explorar de qualquer
            outra forma qualquer conteúdo da Plataforma sem o consentimento
            prévio por escrito da KaizenADS.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            9. Limitação de Responsabilidade
          </h2>
          <p className="text-gray-800">
            A KaizenADS não se responsabiliza por quaisquer danos diretos,
            indiretos, incidentais, especiais ou consequenciais resultantes do
            uso ou da incapacidade de usar a plataforma. A responsabilidade
            máxima da KaizenADS será limitada ao valor pago pelo usuário pelos
            serviços prestados.
          </p>
          <p className="text-gray-800">
            A KaizenADS não se responsabiliza pela suspensão de contas devido a
            violações das políticas do Google Ads. A conformidade com as
            políticas do Google Ads é de exclusiva responsabilidade do Usuário.
            Os Usuários devem revisar e entender completamente as{" "}
            <a
              href="https://support.google.com/adspolicy/answer/6008942"
              className="text-blue-600 underline"
            >
              políticas do Google Ads
            </a>{" "}
            antes de criar ou gerenciar campanhas publicitárias. Em caso de
            dúvidas, os Usuários devem procurar o suporte do Google Ads para
            garantir a conformidade.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            10. Rescisão e Suspensão de Serviços
          </h2>
          <p className="text-gray-800">
            A KaizenADS pode rescindir ou suspender o acesso do Usuário à
            Plataforma a qualquer momento, com ou sem aviso prévio, por violação
            destes Termos de Uso ou por qualquer outra razão considerada
            apropriada.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            11. Modificações nos Termos
          </h2>
          <p className="text-gray-800">
            A KaizenADS reserva-se o direito de modificar estes Termos de Uso a
            qualquer momento. Notificaremos os Usuários sobre quaisquer
            alterações significativas por meio de nosso site ou por e-mail. O
            uso contínuo da Plataforma após a notificação constitui sua
            aceitação dos termos modificados.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            12. Lei Aplicável e Foro
          </h2>
          <p className="text-gray-800">
            Estes Termos de Uso são regidos pelas leis da República Federativa
            do Brasil. Qualquer disputa relacionada a estes Termos será
            resolvida no foro da comarca de São Paulo, SP, Brasil, com exclusão
            de qualquer outro, por mais privilegiado que seja.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            13. Contato
          </h2>
          <p className="text-gray-800">
            Se você tiver dúvidas sobre estes Termos de Uso ou sobre a
            KaizenADS, entre em contato conosco pelo e-mail{" "}
            <a
              href="mailto:support@kaizenads.io"
              className="text-blue-600 underline"
            >
              support@kaizenads.io
            </a>
            . Nosso horário de atendimento é de segunda a sexta-feira, das 9h às
            18h (horário de Brasília). Faremos o possível para responder às suas
            consultas dentro de 2 dias úteis.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">
            14. Disposições Gerais
          </h2>
          <p className="text-gray-800">
            Caso qualquer disposição destes Termos de Uso venha a ser
            considerada ilegal, nula ou inexequível por qualquer razão, as
            disposições restantes continuarão em pleno vigor e efeito.
          </p>
          <p className="text-gray-800">
            A falha da KaizenADS em exigir o cumprimento de qualquer disposição
            destes Termos de Uso não constitui renúncia a essa disposição,
            podendo a KaizenADS exigir o seu cumprimento a qualquer tempo.
          </p>
          <p className="text-gray-800">
            Estes Termos de Uso constituem o acordo integral entre o Usuário e a
            KaizenADS no que diz respeito ao uso da plataforma, substituindo
            quaisquer acordos anteriores.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
