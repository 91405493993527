import React, { useState, useEffect, useRef } from "react";
import { getCurrentCpc, setCpcMin, setCpcMax } from "../utils/api";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const CpcEditor = ({ campaignId, adGroupId, onClose }) => {
  const [cpcMinValues, setCpcMinValues] = useState({
    dawn: "",
    morning: "",
    afternoon: "",
    evening: "",
  });
  const [cpcMaxValues, setCpcMaxValues] = useState({
    dawn: "",
    morning: "",
    afternoon: "",
    evening: "",
  });

  const [loading, setLoading] = useState(true); // Estado para o loading

  // Referências para armazenar os valores anteriores
  const previousMinValues = useRef({});
  const previousMaxValues = useRef({});

  const fetchCpcValues = async () => {
    try {
      setLoading(true); // Inicia o loading
      const cpcData = await getCurrentCpc(campaignId, adGroupId);
      if (cpcData) {
        const { other } = cpcData;

        const initialMinValues = {
          dawn: other?.cpcMin?.dawn || "",
          morning: other?.cpcMin?.morning || "",
          afternoon: other?.cpcMin?.afternoon || "",
          evening: other?.cpcMin?.evening || "",
        };

        const initialMaxValues = {
          dawn: other?.cpcMax?.dawn || "",
          morning: other?.cpcMax?.morning || "",
          afternoon: other?.cpcMax?.afternoon || "",
          evening: other?.cpcMax?.evening || "",
        };

        setCpcMinValues(initialMinValues);
        setCpcMaxValues(initialMaxValues);

        // Armazena os valores iniciais
        previousMinValues.current = { ...initialMinValues };
        previousMaxValues.current = { ...initialMaxValues };
      }
    } catch (error) {
      toast.error("Erro ao carregar os valores de CPC.");
    } finally {
      setLoading(false); // Termina o loading
    }
  };

  const handleSaveCpc = (fieldType, period, value) => {
    if (!value) return; // Se o campo estiver vazio, não faz nada

    const currentValue =
      fieldType === "min"
        ? previousMinValues.current[period]
        : previousMaxValues.current[period];

    // Verifica se o valor mudou em relação ao valor atual
    if (parseFloat(value) === parseFloat(currentValue)) return;

    const cpcValue = parseFloat(value);
    if (cpcValue > 0.3) {
      confirmAlert({
        title: "Confirmação de Aumento",
        message: `O CPC para o período ${period} é maior que R$ 0.30. Tem certeza que deseja continuar?`,
        buttons: [
          {
            label: "Sim",
            onClick: () => updateCpcValue(fieldType, period, value),
          },
          {
            label: "Não",
            onClick: () => {
              // Restaura o valor original do campo ao clicar em "Não"
              if (fieldType === "min") {
                setCpcMinValues((prev) => ({
                  ...prev,
                  [period]: previousMinValues.current[period],
                }));
              } else {
                setCpcMaxValues((prev) => ({
                  ...prev,
                  [period]: previousMaxValues.current[period],
                }));
              }
            },
          },
        ],
      });
    } else {
      updateCpcValue(fieldType, period, value);
    }
  };

  const updateCpcValue = async (fieldType, period, value) => {
    try {
      let response;
      if (fieldType === "min") {
        response = await setCpcMin(campaignId, adGroupId, {
          ...cpcMinValues,
          [period]: value,
        });
        setCpcMinValues((prev) => ({ ...prev, [period]: value }));
      } else if (fieldType === "max") {
        response = await setCpcMax(campaignId, adGroupId, {
          ...cpcMaxValues,
          [period]: value,
        });
        setCpcMaxValues((prev) => ({ ...prev, [period]: value }));
      }

      // Verifica se o status da resposta é 201 (Created)
      if (response && response.success) {
        // Atualiza o valor armazenado após a mudança
        if (fieldType === "min") {
          previousMinValues.current[period] = value;
        } else {
          previousMaxValues.current[period] = value;
        }
        toast.success(`${period} atualizado com sucesso!`);
      } else {
        toast.error(
          `Erro ao atualizar o CPC: ${response?.message || "Erro desconhecido"}`
        );
      }
    } catch (error) {
      toast.error(
        `Erro ao atualizar o CPC: ${error.message || "Erro desconhecido"}`
      );
    }
  };

  useEffect(() => {
    fetchCpcValues();
  }, [campaignId]);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800">Editar CPCs</h2>
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          <p className="ml-2 text-gray-600">Carregando...</p>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4">
          {/* CPC Min */}
          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-800 mb-4">CPC Min</h3>
            {["dawn", "morning", "afternoon", "evening"].map((period) => (
              <div key={period} className="relative mb-4">
                <input
                  type="text"
                  id={`cpcMin-${period}`}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-800 placeholder-transparent focus:outline-none focus:border-blue-500"
                  placeholder="0.01-0.50"
                  value={cpcMinValues[period]}
                  onChange={(e) =>
                    setCpcMinValues({
                      ...cpcMinValues,
                      [period]: e.target.value,
                    })
                  }
                  onBlur={(e) => handleSaveCpc("min", period, e.target.value)}
                />
                <label
                  htmlFor={`cpcMin-${period}`}
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-blue-500"
                >
                  {period === "dawn"
                    ? "00:00-05:59"
                    : period === "morning"
                    ? "06:00-11:59"
                    : period === "afternoon"
                    ? "12:00-17:59"
                    : "18:00-23:59"}
                </label>
              </div>
            ))}
          </div>

          {/* CPC Max */}
          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-800 mb-4">CPC Max</h3>
            {["dawn", "morning", "afternoon", "evening"].map((period) => (
              <div key={period} className="relative mb-4">
                <input
                  type="text"
                  id={`cpcMax-${period}`}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-800 placeholder-transparent focus:outline-none focus:border-blue-500"
                  placeholder="0.02-0.50"
                  value={cpcMaxValues[period]}
                  onChange={(e) =>
                    setCpcMaxValues({
                      ...cpcMaxValues,
                      [period]: e.target.value,
                    })
                  }
                  onBlur={(e) => handleSaveCpc("max", period, e.target.value)}
                />
                <label
                  htmlFor={`cpcMax-${period}`}
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-blue-500"
                >
                  {period === "dawn"
                    ? "00:00-05:59"
                    : period === "morning"
                    ? "06:00-11:59"
                    : period === "afternoon"
                    ? "12:00-17:59"
                    : "18:00-23:59"}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CpcEditor;
