import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { CreditsContext } from "../contexts/CreditsContext";
import {
  FaBullhorn,
  FaChevronDown,
  FaChevronUp,
  FaTachometerAlt,
  FaPen,
  FaGoogle,
  FaLock,
  FaTools,
  FaUserShield,
  FaUsers,
  FaPlus,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import GeneratePix from "./GeneratePix"; // Importando o componente do modal

const Sidebar = () => {
  const { credits } = useContext(CreditsContext);
  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasGadsIntegration, setHasGadsIntegration] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isPixModalOpen, setIsPixModalOpen] = useState(false); // Estado para controlar o modal PIX
  const didMountRef = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (didMountRef.current) return;
    didMountRef.current = true;

    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      setUsername(payload.username);
      setIsAdmin(payload.scopes && payload.scopes.includes("admin"));
      setHasGadsIntegration(
        payload.scopes &&
          (payload.scopes.includes("gads-integration") ||
            payload.scopes.includes("projects"))
      );
    }

    if (location.pathname.includes("/campaigns")) {
      setActiveMenu("campaigns");
    } else if (location.pathname.includes("/kauto")) {
      setActiveMenu("contentCreation");
    } else if (location.pathname.includes("/google-ads-api")) {
      setActiveMenu("googleAdsAPI");
    } else if (location.pathname.includes("/admin")) {
      setActiveMenu("admin");
    }
  }, [location.pathname]);

  const toggleMenu = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const toggleSidebar = () => {
    setIsMinimized((prev) => !prev);
  };

  return (
    <>
      <div
        className={`bg-gray-900 text-white ${
          isMinimized ? "w-20" : "w-64"
        } min-h-screen flex flex-col text-sm fixed transition-all duration-300`}
      >
        <div className="p-4 border-b border-gray-700 flex items-center justify-between">
          {!isMinimized && (
            <div className="text-lg font-bold text-center text-gray-200">
              KaizenADS
            </div>
          )}
          <button
            onClick={toggleSidebar}
            className="text-gray-400 hover:text-gray-200 transition-colors duration-200"
          >
            {isMinimized ? <FaAngleRight /> : <FaAngleLeft />}
          </button>
        </div>
        <div className="p-4 border-b border-gray-700">
          {!isMinimized && (
            <>
              <div className="text-xs text-gray-400">Usuário: {username}</div>
              <div className="text-xs text-gray-400">Créditos: {credits}</div>
              <button
                onClick={() => setIsPixModalOpen(true)} // Abre o modal PIX
                className="mt-3 flex items-center justify-center bg-white text-black text-sm py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition duration-200"
              >
                <FaPlus className="mr-2" />
                Adicionar mais créditos
              </button>
            </>
          )}
        </div>
        <ul className="mt-4 flex-grow">
          <li
            className={`px-4 py-2 hover:bg-gray-700 flex items-center ${
              location.pathname === "/dashboard" ? "font-bold" : ""
            } ${isMinimized ? "justify-center" : "text-gray-300"}`}
          >
            <FaTachometerAlt className="text-gray-400" />
            {!isMinimized && (
              <Link to="/dashboard" className="ml-2">
                Dashboard
              </Link>
            )}
          </li>
          <li
            className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
              activeMenu === "campaigns" ? "bg-gray-800" : ""
            } ${isMinimized ? "justify-center" : "text-gray-300"}`}
            onClick={() => toggleMenu("campaigns")}
          >
            <div className="flex items-center">
              <FaBullhorn className="text-gray-400" />
              {!isMinimized && <span className="ml-2">Campanhas</span>}
            </div>
            {!isMinimized &&
              (activeMenu === "campaigns" ? (
                <FaChevronUp className="text-gray-400" />
              ) : (
                <FaChevronDown className="text-gray-400" />
              ))}
          </li>
          {activeMenu === "campaigns" && !isMinimized && (
            <ul className="pl-6">
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
                  location.pathname === "/campaigns" ? "font-bold" : ""
                }`}
              >
                <Link to="/campaigns">Criação de Campanha</Link>
              </li>
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
                  location.pathname === "/campaigns-history" ? "font-bold" : ""
                }`}
              >
                <Link to="/campaigns-history">Histórico de Campanhas</Link>
              </li>
            </ul>
          )}
          <li
            className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
              activeMenu === "contentCreation" ? "bg-gray-800" : ""
            } ${isMinimized ? "justify-center" : "text-gray-300"}`}
            onClick={() => toggleMenu("contentCreation")}
          >
            <div className="flex items-center">
              <FaPen className="text-gray-400" />
              {!isMinimized && (
                <span className="ml-2">Criação de conteúdo</span>
              )}
            </div>
            {!isMinimized &&
              (activeMenu === "contentCreation" ? (
                <FaChevronUp className="text-gray-400" />
              ) : (
                <FaChevronDown className="text-gray-400" />
              ))}
          </li>
          {activeMenu === "contentCreation" && !isMinimized && (
            <ul className="pl-6">
              <li className="px-4 py-2 flex items-center text-gray-500 cursor-not-allowed">
                <FaTools className="mr-2" />
                <span>KAuto Article</span>
                <FaLock className="ml-auto text-gray-400" />
              </li>
              <li className="px-4 py-2 flex items-center text-gray-500 cursor-not-allowed">
                <FaTools className="mr-2" />
                <span>KAuto Keyword Planner</span>
                <FaLock className="ml-auto text-gray-400" />
              </li>
              <li className="px-4 py-2 flex items-center text-gray-500 cursor-not-allowed">
                <FaTools className="mr-2" />
                <span>KAuto Policy Detector</span>
                <FaLock className="ml-auto text-gray-400" />
              </li>
            </ul>
          )}

          {hasGadsIntegration && (
            <>
              <li
                className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
                  activeMenu === "googleAdsAPI" ? "bg-gray-800" : ""
                } ${isMinimized ? "justify-center" : "text-gray-300"}`}
                onClick={() => toggleMenu("googleAdsAPI")}
              >
                <div className="flex items-center">
                  <FaGoogle className="text-gray-400" />
                  {!isMinimized && <span className="ml-2">Google ADS API</span>}
                </div>
                {!isMinimized &&
                  (activeMenu === "googleAdsAPI" ? (
                    <FaChevronUp className="text-gray-400" />
                  ) : (
                    <FaChevronDown className="text-gray-400" />
                  ))}
              </li>
              {activeMenu === "googleAdsAPI" && !isMinimized && (
                <ul className="pl-6">
                  <li
                    className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
                      location.pathname === "/google-ads-api/projects"
                        ? "font-bold"
                        : ""
                    }`}
                  >
                    <Link to="/google-ads-api/projects">
                      Gerenciar Projetos
                    </Link>
                  </li>
                </ul>
              )}
            </>
          )}

          {isAdmin && (
            <>
              <li
                className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
                  activeMenu === "admin" ? "bg-gray-800" : ""
                } ${isMinimized ? "justify-center" : "text-gray-300"}`}
                onClick={() => toggleMenu("admin")}
              >
                <div className="flex items-center">
                  <FaUserShield className="text-gray-400" />
                  {!isMinimized && <span className="ml-2">Administrador</span>}
                </div>
                {!isMinimized &&
                  (activeMenu === "admin" ? (
                    <FaChevronUp className="text-gray-400" />
                  ) : (
                    <FaChevronDown className="text-gray-400" />
                  ))}
              </li>
              {activeMenu === "admin" && !isMinimized && (
                <ul className="pl-6">
                  <li
                    className={`px-4 py-2 hover:bg-gray-700 text-gray-300 flex items-center ${
                      location.pathname === "/admin/users" ? "font-bold" : ""
                    }`}
                  >
                    <FaUsers className="mr-2 text-gray-400" />
                    <Link to="/admin/users">Usuários</Link>
                  </li>
                </ul>
              )}
            </>
          )}
        </ul>
        {!isMinimized && (
          <>
            <div className="p-4 text-xs text-gray-400 text-center border-t border-gray-700">
              As funções de "Criação de conteúdo" e "Google Ads API" estão em
              testes internos por hora.
            </div>
            <div className="p-4 border-t border-gray-700 text-center text-xs">
              <Link
                to="/logout"
                className="block text-red-500 hover:text-red-600 transition-colors duration-200"
              >
                Logout
              </Link>
            </div>
          </>
        )}
      </div>
      {/* Modal PIX */}
      {isPixModalOpen && (
        <GeneratePix isOpen={isPixModalOpen} setIsOpen={setIsPixModalOpen} />
      )}
    </>
  );
};

export default Sidebar;
