import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import CampaignCreation from "./pages/CampaignCreation";
import Auth from "./pages/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import TermsOfService from "./pages/TermsOfService";
import CampaignsHistory from "./pages/CampaignsHistory";
import AccountManager from "./pages/AccountManager";
import AccessDeniedPage from "./pages/AccessDenied";
import IntegrateAccount from "./pages/IntegrateAccount";
import ProjectDetails from "./pages/ProjectDetails"; // Importe a nova página
import Projects from "./pages/Projects";
import ProjectPerformance from "./pages/ProjectPerformance";
import SingleCampaignPerformance from "./pages/SingleCampaignPerformance";

function ProtectedLayout({ children }) {
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1 ml-64 flex flex-col">
        <Navbar />
        <div className="p-4 w-full">
          <div className="container mx-auto max-w-full lg:max-w-screen-xl">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/termos" element={<TermsOfService />} />

        {/* Rotas Protegidas */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <ProtectedLayout>
                <Routes>
                  <Route path="campaigns" element={<CampaignCreation />} />
                  <Route
                    path="campaigns-history"
                    element={<CampaignsHistory />}
                  />
                  <Route path="admin/users" element={<AccountManager />} />
                  <Route
                    path="/google-ads-api/integrate-account"
                    element={<IntegrateAccount />}
                  />
                  <Route
                    path="/google-ads-api/projects"
                    element={<Projects />}
                  />
                  <Route path="/project/:id" element={<ProjectDetails />} />
                  <Route
                    path="/project/:id/performance"
                    element={<ProjectPerformance />}
                  />
                  <Route
                    path="/project/:id/performance/:campaignId/:adGroupId"
                    element={<SingleCampaignPerformance />}
                  />
                  <Route path="denied" element={<AccessDeniedPage />} />
                  <Route
                    path="*"
                    element={<Navigate to="/campaigns" replace />}
                  />
                </Routes>
              </ProtectedLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
