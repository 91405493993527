import React, { useState, useContext } from "react";
import BasicInfoSection from "../components/BasicInfoSection";
import KeywordSection from "../components/KeywordSection";
import AdvancedSettingsSection from "../components/AdvancedSettingsSection";
import LoadingButton from "../components/LoadingButton";
import CopyCsvButton from "../components/CopyCsvButton";
import AlertMessage from "../components/AlertMessage";
import { createCampaign, checkCampaignDuplicate } from "../utils/api";
import { CreditsContext } from "../contexts/CreditsContext"; // Importando o contexto

const CampaignForm = () => {
  const [campaignData, setCampaignData] = useState({
    finalUrl: "",
    keywords: "",
    blacklist_keywords: "",
    cta_for_headline_1: "",
    cpcMax: 0.1,
    country: "BR",
    manualUTM: "",
    manualMedium: "",
    manualSource: "",
    label: "",
    conversionGoal: "",
    startDate: "",
    customCampaignName: "",
    customGroupName: "",
    campaignType: "Manual CPC",
    noUtm: false,
    budget: "",
    customSitelinkPrefix: "", // Adicionando o campo customSitelinkPrefix
  });
  const [isManualUtm, setIsManualUtm] = useState(false);
  const [isLabel, setIsLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvContent, setCsvContent] = useState("");
  const [copyMessage, setCopyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { updateCredits } = useContext(CreditsContext); // Usando o contexto para atualizar os créditos

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setCampaignData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const cleanCampaignData = (data) => {
    // Remove propriedades indefinidas ou vazias
    const cleanedData = {};
    for (const key in data) {
      if (data[key] !== undefined && data[key] !== "") {
        cleanedData[key] = data[key];
      }
    }
    return cleanedData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      // Se customCampaignName estiver definido, verificar campanha duplicada
      if (campaignData.customCampaignName) {
        const duplicateCheckResponse = await checkCampaignDuplicate({
          campaignName: campaignData.customCampaignName,
        });

        if (
          duplicateCheckResponse.success &&
          duplicateCheckResponse.data.length > 0
        ) {
          const userConfirmed = window.confirm(
            "Já existe uma campanha semelhante no sistema. Tem certeza que deseja continuar?"
          );
          if (!userConfirmed) {
            setIsLoading(false);
            return;
          }
        }
      }

      // Limpar o payload antes de enviar
      const cleanedCampaignData = cleanCampaignData(campaignData);

      const response = await createCampaign(cleanedCampaignData);
      if (response.status === 200) {
        setCsvContent(response.data.csv);
        setSuccessMessage("Campanha criada com sucesso!");
        setCampaignData((prevData) => ({
          ...prevData,
          finalUrl: "",
          keywords: "",
          cta_for_headline_1: "",
          manualUTM: "",
          manualMedium: "",
          manualSource: "",
          customSitelinkPrefix: "", // Reseta o campo customSitelinkPrefix
        }));

        // Atualiza os créditos após criar a campanha
        await updateCredits();
      } else {
        setErrorMessage(`Erro: ${response.data``.message}`);
      }
    } catch (error) {
      // Verifica se o erro está no formato esperado
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(`Erro: ${error.response.data.message}`);
      } else {
        setErrorMessage("Erro desconhecido ocorreu.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyCsv = () => {
    navigator.clipboard.writeText(csvContent).then(() => {
      setCopyMessage("CSV copiado para o clipboard!");
      setTimeout(() => setCopyMessage(""), 2000);
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded max-w-4xl mx-auto px-8 pt-6 pb-8 mb-4"
    >
      <div className="grid grid-cols-2 gap-4">
        <BasicInfoSection
          campaignData={campaignData}
          handleInputChange={handleInputChange}
        />
        <KeywordSection
          campaignData={campaignData}
          handleInputChange={handleInputChange}
        />
      </div>
      <AdvancedSettingsSection
        campaignData={campaignData}
        handleInputChange={handleInputChange}
        isManualUtm={isManualUtm}
        setIsManualUtm={setIsManualUtm}
        isLabel={isLabel}
        setIsLabel={setIsLabel}
      />
      <div className="text-center mb-4">
        <LoadingButton isLoading={isLoading} />
        {csvContent && !isLoading && (
          <CopyCsvButton handleCopyCsv={handleCopyCsv} />
        )}
      </div>
      <AlertMessage
        errorMessage={errorMessage}
        successMessage={successMessage}
        copyMessage={copyMessage}
      />

      {/* Texto sobre termos de uso e criptografia */}
      <div className="text-sm text-gray-600 mt-4 text-center">
        <p>
          Ao gerar esta campanha, você aceita os{" "}
          <a href="/termos" className="text-blue-600 underline">
            termos de uso
          </a>{" "}
          da plataforma KaizenADS.
        </p>
        <p className="mt-2">
          Os dados enviados para a plataforma são criptografados de ponta a
          ponta. Após a campanha ser finalizada, apenas o usuário que a criou
          poderá visualizá-la.
        </p>
      </div>
    </form>
  );
};

export default CampaignForm;
