import React from "react";

const BasicInfoSection = ({ campaignData, handleInputChange }) => (
  <div>
    <h2 className="text-xl font-bold text-blue-600 mb-4">
      Informações Básicas
    </h2>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="customCampaignName"
      >
        Nome Personalizado da Campanha
      </label>
      <input
        id="customCampaignName"
        type="text"
        value={campaignData.customCampaignName}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      <p className="text-gray-600 text-sm">
        Se não preenchido, será usado o padrão do sistema: título do artigo +
        utm
      </p>
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="customGroupName"
      >
        Nome Personalizado do Grupo
      </label>
      <input
        id="customGroupName"
        type="text"
        value={campaignData.customGroupName}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      <p className="text-gray-600 text-sm">
        Se não preenchido, será usado o padrão do sistema: título do artigo +
        utm
      </p>
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="budget"
      >
        Orçamento
      </label>
      <input
        id="budget"
        type="number"
        value={campaignData.budget}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        step="0.01"
        required
      />
    </div>
    <div className="mb-4">
      <label
        className="block text-blue-600 text-sm font-bold mb-2"
        htmlFor="campaignType"
      >
        Tipo de Campanha
      </label>
      <select
        id="campaignType"
        value={campaignData.campaignType}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        required
      >
        <option value="Manual CPC">Manual CPC</option>
        <option value="Manual CPM" disabled>
          Manual CPM
        </option>
        <option value="Manual CPV" disabled>
          Manual CPV
        </option>
        <option value="Maximize conversions" disabled>
          Maximizar conversões
        </option>
        <option value="Maximize clicks" disabled>
          Maximizar cliques
        </option>
        <option value="Target CPA" disabled>
          CPA alvo
        </option>
        <option value="Target ROAS" disabled>
          ROAS alvo
        </option>
      </select>
    </div>
  </div>
);

export default BasicInfoSection;
