import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCampaignReportByDate } from "../utils/api";
import dayjs from "dayjs";
import CampaignPerformanceTable from "../components/CampaignPerformanceTable";
import CampaignChart from "../components/CampaignChart";

const ProjectPerformance = () => {
  const { id: projectId, campaignId, adGroupId } = useParams();
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [collapseTopRoiOpen, setCollapseTopRoiOpen] = useState(false);
  const [collapseHourlyIntervalsOpen, setCollapseHourlyIntervalsOpen] =
    useState(false);

  const [cpcMin, setCpcMin] = useState(0);
  const [cpcMax, setCpcMax] = useState(0);
  const [topRoiTimes, setTopRoiTimes] = useState([]);
  const [topRoiCpc, setTopRoiCpc] = useState(0);
  const [totalGoogleAdsClicks, setTotalGoogleAdsClicks] = useState(0);
  const [totalConversions, setTotalConversions] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [totalGasto, setTotalGasto] = useState(0);
  const [totalLucro, setTotalLucro] = useState(0);

  const [roiStatus, setRoiStatus] = useState("");
  const [roiPercentage, setRoiPercentage] = useState(0);

  const [roiByPeriod, setRoiByPeriod] = useState({
    period1: { avgRoi: 0, totalLucro: 0, cpcRange: { min: 0, max: 0 } },
    period2: { avgRoi: 0, totalLucro: 0, cpcRange: { min: 0, max: 0 } },
    period3: { avgRoi: 0, totalLucro: 0, cpcRange: { min: 0, max: 0 } },
    period4: { avgRoi: 0, totalLucro: 0, cpcRange: { min: 0, max: 0 } },
  });

  const [hourlyIntervals, setHourlyIntervals] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [utm, setUtm] = useState("");

  const periods = [
    { name: "00:00 até 05:59", start: "00:00", end: "05:59" },
    { name: "06:00 até 11:59", start: "06:00", end: "11:59" },
    { name: "12:00 até 17:59", start: "12:00", end: "17:59" },
    { name: "18:00 até 23:59", start: "18:00", end: "23:59" },
  ];

  const fetchReportData = async (date) => {
    if (projectId && campaignId) {
      setIsLoading(true);
      try {
        const response = await getCampaignReportByDate(
          projectId,
          campaignId,
          adGroupId,
          date
        );

        if (response) {
          const reports = response || [];
          setReportData(reports);
          setError("");

          if (reports.length > 0) {
            const adGroupDetails = reports[0].adGroupDetails;
            setGroupName(adGroupDetails?.name || "Nome do Grupo Desconhecido");
            setUtm(adGroupDetails?.utm_campaign || "UTM Desconhecida");

            // Calcula as informações solicitadas
            calculateSummary(reports);
          }
        } else {
          setReportData([]);
          setError("Erro ao carregar o relatório.");
        }
      } catch (err) {
        setError("Erro ao carregar o relatório.");
        setReportData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const calculateSummary = (reports) => {
    if (reports.length === 0) return;

    // Pega o último relatório
    const latestReport = reports[reports.length - 1];
    const adGroup = latestReport.campaignDetails?.adGroups[0] || {};

    setTotalGoogleAdsClicks(adGroup.metrics?.clicks || 0);
    setTotalConversions(adGroup.metrics?.conversions || 0);

    const calculatedConversionRate = adGroup.metrics?.clicks
      ? ((adGroup.metrics.conversions / adGroup.metrics.clicks) * 100).toFixed(
          2
        )
      : 0;
    setConversionRate(calculatedConversionRate);

    setTotalGasto(((adGroup.metrics?.cost_micros || 0) / 1000000).toFixed(2));
    setTotalLucro((latestReport.roiRevenue || 0).toFixed(2));

    const currentRoi = latestReport.roiPercentage || 0;
    setRoiPercentage(currentRoi);
    calculateRoiStatus(currentRoi);

    setCpcMin(((adGroup.cpc_bid_micros || 0) / 1000000).toFixed(2));
    setCpcMax(((adGroup.cpc_bid_micros || 0) / 1000000).toFixed(2));

    // Não precisamos ajustar top ROI e intervals pois eles dependem de vários registros
  };

  const calculateRoiStatus = (roi) => {
    let status = "";
    if (roi >= 80) {
      status = `Acima da meta (+${(roi - 55).toFixed(2)}% acima da meta)`;
    } else if (roi >= 50 && roi < 80) {
      status = `Dentro da meta (+${(roi - 50).toFixed(2)}% acima da meta)`;
    } else if (roi >= 40 && roi < 50) {
      status = `Perto da meta (-${(50 - roi).toFixed(2)}% abaixo da meta)`;
    } else {
      status = `Muito abaixo da meta (-${(50 - roi).toFixed(
        2
      )}% abaixo da meta)`;
    }
    setRoiStatus(status);
  };

  useEffect(() => {
    fetchReportData(selectedDate);
  }, [projectId, campaignId, selectedDate]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Desempenho do Grupo</h1>

      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Nome do Grupo:
        </label>
        <p className="text-lg text-gray-800">{groupName}</p>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">UTM:</label>
        <p className="text-lg text-gray-800">{utm}</p>
      </div>

      <div className="mb-4">
        <label
          htmlFor="date"
          className="block text-gray-700 font-semibold mb-2"
        >
          Selecionar Data:
        </label>
        <input
          type="date"
          id="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="border border-gray-300 p-2 rounded"
        />
      </div>

      {/* Destaque para Total Gasto, Lucro e ROI Status */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 text-lg">
        <div className="p-4 bg-blue-100 border-l-4 border-blue-500 rounded">
          <p className="font-semibold text-blue-700">Total Gasto:</p>
          <p className="text-2xl font-bold">R$ {totalGasto}</p>
        </div>
        <div className="p-4 bg-blue-100 border-l-4 border-blue-500 rounded">
          <p className="font-semibold text-blue-700">Total de Lucro:</p>
          <p className="text-2xl font-bold">R$ {totalLucro}</p>
        </div>
        <div
          className={`p-4 rounded ${
            roiPercentage >= 80
              ? "bg-green-100 border-l-4 border-green-500"
              : roiPercentage >= 50
              ? "bg-blue-100 border-l-4 border-green-500"
              : roiPercentage >= 40
              ? "bg-yellow-50 border-l-4 border-yellow-400"
              : "bg-red-100 border-l-4 border-red-500"
          }`}
        >
          <p className="font-semibold text-gray-700">ROI Atual:</p>
          <p className="text-2xl font-bold">{roiPercentage.toFixed(2)}%</p>
          <p
            className={`text-sm ${
              roiPercentage >= 50
                ? "text-green-600"
                : roiPercentage >= 40
                ? "text-yellow-600"
                : "text-red-600"
            }`}
          >
            {roiStatus}
          </p>
        </div>
      </div>

      {/* Informações Resumidas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6 text-sm">
        <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded">
          <p className="font-semibold text-blue-600">CPC Mínimo:</p>
          <p>R$ {cpcMin}</p>
        </div>
        <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded">
          <p className="font-semibold text-blue-600">CPC Máximo:</p>
          <p>R$ {cpcMax}</p>
        </div>
        <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded">
          <p className="font-semibold text-blue-600">Horário com Mais ROI:</p>
          <p>{topRoiTimes.length > 0 ? topRoiTimes[0].time : "N/A"}</p>
        </div>
        <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded">
          <p className="font-semibold text-blue-600">
            CPC no Horário com Mais ROI:
          </p>
          <p>R$ {topRoiCpc}</p>
        </div>
        <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded">
          <p className="font-semibold text-blue-600">
            Total de Cliques Google Ads:
          </p>
          <p>{totalGoogleAdsClicks}</p>
        </div>
        <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded">
          <p className="font-semibold text-blue-600">Total de Conversões:</p>
          <p>{totalConversions}</p>
          <p className="text-gray-500">Taxa de Conversão: {conversionRate}%</p>
        </div>
      </div>

      {/* Substituir Top 10 Horários com Maior ROI pelo gráfico */}
      <div className="bg-gray-100 p-2 rounded-lg mb-4">
        <h2 className="text-lg font-semibold text-gray-800 mb-2">
          Gráfico de Desempenho do Grupo
        </h2>
        <CampaignChart
          projectId={projectId}
          campaignId={campaignId}
          adGroupId={adGroupId}
          initialDate={selectedDate}
        />
      </div>

      {/* ROI, CPC Mínimo e Máximo por Intervalo de Horário em um collapse */}
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <div
          className="cursor-pointer text-lg font-semibold text-gray-800 mb-4 flex justify-between items-center"
          onClick={() =>
            setCollapseHourlyIntervalsOpen(!collapseHourlyIntervalsOpen)
          }
        >
          ROI, CPC Mínimo e Máximo por Intervalo de Horário
          <span>{collapseHourlyIntervalsOpen ? "▲" : "▼"}</span>
        </div>
        {collapseHourlyIntervalsOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {hourlyIntervals.map((interval, index) => (
              <div
                key={index}
                className="p-3 bg-yellow-50 border-l-4 border-yellow-400 rounded"
              >
                <p className="font-semibold text-yellow-600">{interval.hour}</p>
                <p>{interval.avgRoi}% ROI</p>
                <p>
                  CPC: R$ {interval.cpcMin} - R$ {interval.cpcMax}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      {isLoading ? (
        <p>Carregando...</p>
      ) : error ? (
        <p className="text-red-500">
          {typeof error === "string"
            ? error
            : error.message || "Ocorreu um erro."}
        </p>
      ) : (
        <CampaignPerformanceTable reports={reportData} singleCampaign={true} />
      )}
    </div>
  );
};

export default ProjectPerformance;
