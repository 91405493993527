import React, { createContext, useState, useEffect } from "react";
import api from "../utils/api";

export const CreditsContext = createContext();

export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await api.get("/accounts/check-credits");
        if (response.data.success) {
          setCredits(response.data.credits);
        }
      } catch (error) {
        console.error("Erro ao obter créditos:", error);
      }
    };

    fetchCredits();
  }, []);

  const updateCredits = async () => {
    try {
      const response = await api.get("/accounts/check-credits");
      if (response.data.success) {
        setCredits(response.data.credits);
      }
    } catch (error) {
      console.error("Erro ao atualizar créditos:", error);
    }
  };

  return (
    <CreditsContext.Provider value={{ credits, setCredits, updateCredits }}>
      {children}
    </CreditsContext.Provider>
  );
};
