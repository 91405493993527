import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { getGoogleAdsAuthUrl } from "../../utils/api";

const IntegrateAccount = ({ projectId }) => {
  const handleIntegrateAccount = async () => {
    try {
      const { authUrl } = await getGoogleAdsAuthUrl();

      if (authUrl) {
        // Armazena temporariamente os dados no localStorage
        localStorage.setItem(
          "googleAdsIntegration",
          JSON.stringify({ projectId })
        );
        window.location.href = authUrl;
      } else {
        toast.error("Falha ao gerar a URL de autenticação.");
      }
    } catch (error) {
      console.error("Erro durante a integração da conta:", error);
      toast.error("Ocorreu um erro ao tentar integrar sua conta.");
    }
  };

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <button
        onClick={handleIntegrateAccount}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200"
      >
        Integrar sua conta Google Ads
      </button>
      <p className="text-gray-500 text-sm mt-2">
        Integrar sua conta Google Ads
      </p>
    </div>
  );
};

export default IntegrateAccount;
